import React from "react";
import { Dropdown, Menu } from "antd";
import {
  DeleteOutlined,
  CopyOutlined,
  EyeOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApi } from "../../Api/useApi.ts";
import { useMutation } from "@tanstack/react-query";

const FormCard = ({ isFetching, formList }) => {
  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = message.useMessage();
  const api = useApi();
  const deleteMutation = useMutation({
    mutationFn: (id) => api.forms.deleteForm(id),
    onSuccess: () => {
      messageApi.success("Form deleted");
      queryClient.invalidateQueries({ queryKey: ["formList"] });
    },
    onError: (error) => {
      messageApi.error("Could not delete form");
      alert(error);
    },
  });

  const handleMenuClick = (e, form) => {
    const { key } = e;
    if (key === "6") {
      deleteMutation.mutate(form?.url_id);
    }
  };

  return (
    <>
      {contextHolder}
      {isFetching ? (
        <div className="relative h-[12rem] border border-zinc-200 shadow rounded-2xl overflow-hidden animate-pulse">
          <div className="absolute z-20 w-full h-full bg-gray-300 opacity-50"></div>
          <div className="absolute flex items-center justify-center w-full h-full"></div>
          <div className="relative z-20 p-4">
            <div className="w-3/4 h-4 mb-2 bg-gray-300 rounded"></div>
            <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
          </div>
          <div className="absolute bottom-0 left-0 z-20 flex items-center justify-between w-full p-4">
            <div className="flex items-center w-12 gap-2 px-2 bg-gray-300 rounded-lg h-7"></div>
            <div className="bg-gray-300 rounded-lg h-7 w-7"></div>
          </div>
        </div>
      ) : (
        formList?.map((form) => (
          <div
            key={form?.id}
            className="relative h-[12rem] border border-zinc-200 shadow rounded-2xl overflow-hidden hover:shadow-xl hover:opacity-80 transition duration-500"
          >
            <a className="w-full h-full" href={`/form-editor/${form?.id}`}>
              <div className="absolute z-20 w-full h-full opacity-50"></div>
              <div className="absolute flex items-center justify-center w-full h-full">
                <img
                  src={form?.img ? form?.img : "./main-logo.svg"}
                  alt="logo"
                  className="w-16 h-16 opacity-30"
                />
              </div>
              <div className="relative z-20 p-4">
                <p className="text-base font-medium truncate ">
                  {form?.title || form?.name}
                </p>
              </div>
            </a>
            <div className="absolute bottom-0 left-0 z-20 flex items-center justify-between w-full p-4">
              <a className="flex items-center gap-2 px-2 text-white transition duration-150 rounded-lg bg-[#191B33] h-7 group hover:bg-[#191B33]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="w-4 h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8c0-3.43 3.262-6 7-6s7 2.57 7 6-3.262 6-7 6c-.423 0-.838-.032-1.241-.094-.9.574-1.941.948-3.06 1.06a.75.75 0 0 1-.713-1.14c.232-.378.395-.804.469-1.26C1.979 11.486 1 9.86 1 8Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <p className="text-xs text-white">0</p>
              </a>

              {/* Dropdown menu */}
              <Dropdown
                overlay={
                  <Menu onClick={(e) => handleMenuClick(e, form)}>
                    <Menu.Item key="1" icon={<FolderOpenOutlined />}>
                      <a
                        href={`/form-editor/${form?.id}`}
                        className="font-semibold "
                      >
                        Open in Editor
                      </a>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<EyeOutlined />}>
                      <a
                        target="_blank"
                        href={`/forms/${form?.url_id}`}
                        className="font-semibold "
                      >
                        Preview
                      </a>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<CopyOutlined />}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard.writeText(
                            `http://localhost:3000/forms/${form?.url_id}`
                          );
                          message.success("Link copied to clipboard");
                        }}
                        className="font-semibold "
                      >
                        Copy Link
                      </a>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="6" icon={<DeleteOutlined />} danger>
                      <button className="flex items-center gap-1 font-semibold text-red-500 hover:text-white">
                        Delete
                      </button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
                className="hover:cursor-pointer"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <div
                    type="button"
                    aria-haspopup="dialog"
                    aria-expanded="false"
                    aria-controls="radix-:rn:"
                    data-state="closed"
                    className="h-7 w-7 z-[300] relative rounded-lg overflow-hidden flex items-center justify-center"
                  >
                    <div className="absolute top-0 left-0 w-full h-full transition duration-300 opacity-0 hover:opacity-20"></div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      data-slot="icon"
                      className="relative z-50 w-4 h-4 pointer-events-none"
                    >
                      <path d="M3 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM8.5 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM15.5 8.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"></path>
                    </svg>
                  </div>
                </a>
              </Dropdown>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default FormCard;

import { REGISTER } from "redux-persist";

//Add routes in snake case (dash seperated)
export const ROUTES = {
  ROOT: "/",
  FORM_INITIALIZE: "/form-initialize",
  FORM_EDITOR: "/form-editor/:id",
  AI_FORM_EDITOR: "/ai-form-editor/:id",
  FORMS: "/forms/:urlId", //For public to view forms
  FORM_VIEW: "/form-view/:formId",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  TEST_VIEW: "/test",
  SIGNUP: "/register",
};

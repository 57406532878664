import React, { useState, useEffect } from "react";
import { Input, Modal, List, Spin, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UserButton } from "@clerk/clerk-react";
import { SignOutButton } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../Api/useApi.ts";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormList,
  addNewForm,
  removeFormList,
} from "../Redux/slices/formSlice.tsx";
import { newFormProps, newThemeProps } from "../Constants/newForm.tsx";
import { useNavigate } from "react-router-dom";
import { PopupButton } from "react-calendly";
import {
  QuestionCircleOutlined,
  PhoneOutlined,
  SettingOutlined,
  TagOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import FeatureCard from "../Components/DashboardComponents/FeatureCard.jsx";
import FormRepliesCard from "../Components/DashboardComponents/FormRepliesCard.jsx";
import FormCard from "../Components/DashboardComponents/FormCard.jsx";
import Workspaces from "../Components/DashboardComponents/Workspaces.jsx";
import { useFormEditorContext } from "../Contexts/FormEditorContext.jsx";
import { useUser } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import {
  convertBackToOldFormat,
  convertFormObject,
} from "../Components/MyEditor/FormObjectConfig.jsx";
import { useQueryClient } from "@tanstack/react-query";
import CustomBilling from "../Components/DashboardComponents/CustomBilling.jsx";
import useKeyboardShortcut from "../Components/DashboardComponents/useKeyboardShortcut.jsx";
import CommonLayout from "./CommonLayout.jsx";

const { TextArea } = Input;

const DashboardLayout = () => {
  /* Data Fetchings  */
  const [formType, setFormType] = useState("guided");
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { selectedWorkSpace, setSelectedWorkSpace, setForm } =
    useFormEditorContext();
  const [isAiForm, setIsAiForm] = useState(false);
  const navigate = useNavigate();
  const [workSpaces, setWorkSpaces] = useState([]);
  const api = useApi();
  const { isFetching, data, isSuccess, isError } = useQuery({
    queryKey: ["formList", selectedWorkSpace],
    queryFn: () => api.forms.fetchFormList(selectedWorkSpace),
    staleTime: Infinity,
    onError: (error) => {
      console.error("Error details:", error);
    },
    enabled: !!selectedWorkSpace && selectedWorkSpace !== "undefined",
  });

  useEffect(() => {
    if (isSuccess && data?.data?.forms) {
      dispatch(setFormList(data?.data?.forms));
    }
    if (isError) {
      dispatch(removeFormList());
    }
  }, [isSuccess, data, dispatch, isError]);

  const formList = useSelector((state) => state.forms.forms);

  /* modal states */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  useKeyboardShortcut(["cmd+k", "ctrl+k"], (key, e) => {
    e.preventDefault();

    showModal();
  });
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  const handleOkCreate = () => {
    setIsCreateModalOpen(false);
  };
  const handleCreateCancel = () => {
    setIsCreateModalOpen(false);
  };

  /* change logs */
  useEffect(() => {
    const win = window;

    // Initialize Featurebase widget
    const initializeFeaturebase = () => {
      if (typeof win.Featurebase !== "function") {
        win.Featurebase = function () {
          (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
        };
      }

      win.Featurebase("initialize_changelog_widget", {
        organization: "Buildform", // Replace this with your Featurebase organization name
        placement: "right", // Choose between right, left, top, bottom placement
        theme: "light", // Choose between dark or light theme
        fullscreenPopup: true, // Optional - Open a fullscreen announcement
        locale: "en",
      });
    };

    // Inject the script dynamically
    const scriptId = "featurebase-sdk";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://do.featurebase.app/js/sdk.js";
      document.body.appendChild(script);

      script.onload = () => {
        initializeFeaturebase();
      };
    } else {
      initializeFeaturebase();
    }
  }, []);

  // Function to manually trigger the changelog popup
  const openChangelogPopup = () => {
    if (window.Featurebase) {
      window.Featurebase("manually_open_changelog_popup"); // Manually trigger the changelog popup
    } else {
      console.error("Featurebase is not initialized yet.");
    }
  };

  /* search  */
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(workSpaces);
  const [inputKey, setInputKey] = useState(0); // Key to force re-render

  // Handle search functionality
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    if (value.trim()) {
      const filtered = workSpaces.filter((item) =>
        item.name.toLowerCase().includes(value)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(workSpaces);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      setFilteredData(workSpaces);
      setInputKey((prevKey) => prevKey + 1);
    }
  }, [isModalOpen, workSpaces]);

  /* adding new form */

  const STATUSES = {
    INITIAL: "initial",
    LOADING: "loading",
    SUCCESS: "success",
    ERROR: "error",
  };
  const [formName, setFormName] = useState("");
  const [status, setStatus] = useState(STATUSES.INITIAL);
  const [spin, setSpin] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const formCreateMutation = useMutation({
    mutationFn: (form) => api.forms.createForm(form),
    onSuccess: (data) => {
      //console.log("form created");
      const newObj = convertBackToOldFormat(data?.data);
      dispatch(addNewForm(newObj));
      setTimeout(() => {
        setForm(newObj);
        navigate(`/form-editor/${newObj?.id}`);
        queryClient.invalidateQueries({ queryKey: ["formList"] });
        setIsCreateModalOpen(false);
        setSpin(false);

        setStatus(STATUSES.SUCCESS);
      }, 600);
    },
    onError: (error) => {
      if (error.response) {
        console.log(error?.response);
        message.error(error?.response?.data?.detail || "Something went wrong");
      } else {
      }
      console.error("Error details:", error);
      setSpin(false);
      setIsCreateModalOpen(false);
    },
  });

  const handleCreateNewForm = (theme) => {
    setStatus(STATUSES.LOADING);
    const sessionWorkspace = sessionStorage.getItem("selectedWorkSpace");
    if (!sessionWorkspace || sessionWorkspace === "undefined") {
      message.warning("Please create a workspace first.");
      setStatus(STATUSES.IDLE);
      setIsCreateModalOpen(false);
      setIsCollapsed(false);
      return;
    }
    setSpin(true);
    setTimeout(() => {
      const newForm = newFormProps(formName, selectedWorkSpace, theme);
      const newFormat = convertFormObject(newForm);
      formCreateMutation.mutate(newFormat);
    }, 600);
  };

  const createMutation = useMutation({
    mutationFn: (newTheme) => api.themes.createTheme(newTheme),
    onSuccess: (data) => {
      /* need to create the form then  */
      const theme = data?.data;
      handleCreateNewForm(theme);
    },
    onError: (error) => {
      console.error("Error details:", error);
    },
  });

  const themeCreate = (formType) => {
    switch (formType) {
      case "dynamic":
        console.log("Dynamic form creation triggered");
        break;
      case "guided":
        const newTheme = newThemeProps();
        createMutation.mutate(newTheme);
        break;
      default:
        console.log("Unknown form type:", formType);
        break;
    }
  };

  const handleSignOut = () => {
    sessionStorage.removeItem("selectedWorkSpace");
    dispatch(removeFormList());
  };

  const dashboardSidebar = (
    <div className="w-[20rem] h-full bg-white px-5 pt-4 flex flex-col relative">
      {/* user nav sec */}

      <UserButton showName="true" afterSignOutUrl="/login">
        <UserButton.SignOutButton>
          <SignOutButton
            className="flex items-center justify-center w-full h-10 px-4 py-2 text-xs font-medium transition rounded-lg whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-zinc-900 text-zinc-50 hover:bg-zinc-700"
            onClick={handleSignOut}
          >
            Sign Oudfvdvst
          </SignOutButton>
        </UserButton.SignOutButton>
        <UserButton.UserProfilePage
          label="Billing"
          url="custom"
          labelIcon={<TagOutlined />}
        >
          <CustomBilling />
        </UserButton.UserProfilePage>
      </UserButton>

      {/* Search sec */}

      <div onClick={showModal}>
        <Input
          placeholder="Search"
          variant="filled"
          prefix={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="#959595"
              aria-hidden="true"
              data-slot="icon"
              className="h-3.5 w-3.5"
            >
              <path
                fillRule="evenodd"
                d="M8.5 2.75a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5Zm0 1.5a4.25 4.25 0 1 1 0 8.5 4.25 4.25 0 0 1 0-8.5Zm6.72 11.28a.75.75 0 0 1-1.06 1.06l-3.25-3.25a.75.75 0 0 1 1.06-1.06l3.25 3.25Z"
              ></path>
            </svg>
          }
          suffix={<span className=" text-[#959595]">⌘ K</span>}
          className="rounded-lg mx-0 mt-5 bg-[#F4F4F5]"
        />
      </div>

      {/* add workspace sec */}
      <Workspaces
        workSpaces={workSpaces}
        setWorkSpaces={setWorkSpaces}
        selectedWorkSpace={selectedWorkSpace}
        setSelectedWorkSpace={setSelectedWorkSpace}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />

      {/* Helps */}

      <div className="flex flex-col items-start justify-center pb-4 mt-auto">
        <p className="mb-2 text-xs font-medium opacity-30">Helps</p>
        <div className="flex flex-col gap-4 text-xs font-semibold ">
          <button className="flex items-center gap-2 text-start">
            <a href="https://buildform.featurebase.app/help" target="_blank">
              <QuestionCircleOutlined /> Help Docs
            </a>
          </button>

          <PopupButton
            url="https://calendly.com/ronath-buildform/30min?month=2024-09"
            rootElement={document.getElementById("root")}
            text={
              <button className="flex items-center gap-2 text-start">
                <PhoneOutlined /> Talk to us
              </button>
            }
          />
          <button
            onClick={openChangelogPopup}
            className="flex items-center gap-2 hover:font-bold text-start"
          >
            <SettingOutlined />
            Change logs <span id="fb-update-badge"></span>
          </button>
        </div>
      </div>

      {/* unlimited Replies sec */}
      <div className="pt-2 pb-4 border-t border-zinc-200">
        <div className="text-xs transition duration-150 text-zinc-500">
          <p className="text-zinc-950 text-[0.65rem] font-medium uppercase tracking-widest">
            UNLIMITED REPLIES
          </p>
          <div className="w-full h-1.5 rounded-full bg-zinc-100 my-3 overflow-hidden">
            <div className="w-full h-full bg-blue-600 rounded-full"></div>
          </div>
          <div className="text-left">
            If you require specific features, such as brand removal, analytics,
            integrations or anything else, Share it with us{"\t"}
            <a
              target="_blank"
              className="inline-flex items-center font-medium text-blue-600 underline transition duration-150 hover:cursor-pointer hover:opacity-60"
              href="https://buildform.featurebase.app/"
            >
              here.
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <CommonLayout sidebar={dashboardSidebar}>
        {/* main section */}

        <div className="relative w-full h-full px-8 overflow-y-auto transition duration-200 border shadow-xl rounded-2xl border-zinc-200">
          <section className="flex flex-col justify-between gap-4 pt-3 lg:items-center lg:flex-row">
            <div className="flex flex-col gap-4 mt-5">
              <h1 className="flex flex-col items-center mb-1 text-xl ">
                <p className="text-[2rem] font-bold text-start">
                  Hey {user?.firstName} 👋
                </p>
              </h1>
              <p className="hidden text-sm text-zinc-500">
                Find all the forms you have created
              </p>
              <p className="hidden text-lg font-medium text-start">My Forms</p>
            </div>
            <button
              type="primary"
              className="flex items-center justify-center mt-5 px-2 py-2 font-medium text-white bg-[#191B33] rounded-lg hover:bg-zinc-800"
              onClick={showCreateModal}
            >
              <PlusOutlined className=" w-3.5 h-3.5 text-base"> </PlusOutlined>
              <span className="ml-1 text-sm">New Form</span>
            </button>
          </section>
          <div className="grid gap-4 my-6 lg:grid-cols-3">
            {/* Replies card */}
            <FormRepliesCard />
            {/* Feature Card */}
            <FeatureCard />
          </div>
          <p className="mt-6 mb-3 text-sm text-start text-zinc-500">
            Recent forms
          </p>
          <section className="grid grid-cols-1 gap-4 pb-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
            <button
              type="button"
              aria-haspopup="dialog"
              aria-expanded="false"
              aria-controls="radix-:rk:"
              data-state="closed"
              onClick={showCreateModal}
              className="h-[12rem] relative border border-zinc-200 border-dashed rounded-2xl hover:scale-[.98] transition duration-500 flex items-center gap-1 flex-col justify-center hover:bg-zinc-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="w-6 h-6"
              >
                <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z"></path>
              </svg>
              <p className="text-sm text-zinc-500">Create a form</p>
            </button>
            <FormCard formList={formList} isFetching={isFetching} />
          </section>
        </div>
      </CommonLayout>
      {/* Search Modal */}
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        closeIcon={null}
        transitionName={""}
        afterOpen={() => setInputKey((prevKey) => prevKey + 1)}
      >
        <div
          style={{
            maxHeight: "160px",
            overflow: "auto",
            scrollbarColor: "dark",
          }}
        >
          <Input
            key={inputKey}
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            variant="filled"
            autoFocus
          />
          <List
            dataSource={filteredData} // Display filtered data only
            renderItem={(item) => (
              <List.Item
                key={item._id}
                onClick={() => {
                  setSelectedWorkSpace(item?._id);
                  handleCancel();
                }}
                className="font-medium hover:font-semibold"
              >
                {item?.name}
              </List.Item>
            )}
          />
        </div>
      </Modal>

      {/* Create Form modal */}
      <Modal
        title={null}
        open={isCreateModalOpen}
        onOk={handleOkCreate}
        onCancel={handleCreateCancel}
        footer={[]}
        closeIcon={null}
        width={480}
        transitionName={""}
      >
        <>
          <div className="pb-0 ">
            <div className="grid grid-cols-2 p-1 rounded-lg bg-zinc-100">
              <button
                className={` flex items-center justify-center py-1.5 font-medium capitalize relative hover:cursor-pointer ${
                  formType === "dynamic"
                    ? " bg-white shadow rounded-lg"
                    : "text-zinc-500 hover:text-zinc-950"
                }`}
                onClick={() => {
                  setFormType("dynamic");
                }}
                disabled
              >
                <p className="text-[0.8rem]">BuildForm AI</p>
                <div className="ml-2 -mr-7 py-1 px-2 shadow bg-black text-white rounded-full text-[0.6rem] leading-none">
                  TBA
                </div>
              </button>
              <button
                className={` border-transparent flex items-center justify-center py-1.5 font-medium rounded-lg capitalize relative ${
                  formType === "guided"
                    ? " bg-white shadow"
                    : "text-zinc-500 hover:text-zinc-950"
                }`}
                onClick={() => {
                  setFormType("guided");
                }}
              >
                <p className="text-[0.8rem]">Start from Scratch</p>
              </button>
            </div>
          </div>

          {formType === "dynamic" ? (
            <div className="mt-4 ">
              <div className="rounded-xl w-full bg-purple-50 flex p-4 gap-1.5 items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="h-[0.8rem] min-w-[0.8rem] text-purple-600 mt-0.5"
                >
                  <path d="M10 1a6 6 0 0 0-3.815 10.631C7.237 12.5 8 13.443 8 14.456v.644a.75.75 0 0 0 .572.729 6.016 6.016 0 0 0 2.856 0A.75.75 0 0 0 12 15.1v-.644c0-1.013.762-1.957 1.815-2.825A6 6 0 0 0 10 1ZM8.863 17.414a.75.75 0 0 0-.226 1.483 9.066 9.066 0 0 0 2.726 0 .75.75 0 0 0-.226-1.483 7.553 7.553 0 0 1-2.274 0Z"></path>
                </svg>
                <p className="text-xs text-purple-600">
                  BuildForm AI will be available in the near future.
                </p>
              </div>
            </div>
          ) : (
            <div className="mt-4 ">
              <div className="rounded-xl w-full bg-blue-50 flex p-4 gap-1.5 items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="h-[0.8rem] min-w-[0.8rem] text-blue-600 mt-0.5"
                >
                  <path d="M10 1a6 6 0 0 0-3.815 10.631C7.237 12.5 8 13.443 8 14.456v.644a.75.75 0 0 0 .572.729 6.016 6.016 0 0 0 2.856 0A.75.75 0 0 0 12 15.1v-.644c0-1.013.762-1.957 1.815-2.825A6 6 0 0 0 10 1ZM8.863 17.414a.75.75 0 0 0-.226 1.483 9.066 9.066 0 0 0 2.726 0 .75.75 0 0 0-.226-1.483 7.553 7.553 0 0 1-2.274 0Z"></path>
                </svg>
                <p className="text-xs text-blue-600">
                  AI statements are available for Start From Scratch Projects.
                </p>
              </div>
            </div>
          )}
          <div className="pt-4 ">
            <div className="flex flex-col w-full">
              <div className="flex flex-col mb-8">
                <p className="font-semibold text-sm mb-0.5">Form name</p>
                <p className="mb-3 text-sm text-zinc-500">
                  The from name will be displayed when sharing.{" "}
                </p>
                <input
                  className="flex w-full h-10 px-3 py-2 text-xs transition duration-150 bg-white border rounded-lg shadow border-zinc-200 ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-zinc-950 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 input placeholder:text-zinc-500"
                  required=""
                  placeholder="e.g. Feedback form"
                  type="text"
                  onChange={(e) => setFormName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      themeCreate(formType);
                    }
                  }}
                />
              </div>
              {formType === "dynamic" && (
                <div className="flex flex-col mb-8">
                  <p className="font-semibold text-sm mb-0.5">
                    Describe the goal of this form
                  </p>
                  <p className="mb-3 text-sm text-zinc-500">
                    Feel free to be as specific as you like. You can describe
                    each question and any logic you want to implement.
                  </p>

                  <div className="bg-white border border-zinc-200 rounded-lg resize-none w-full p-1 text-xs focus:outline-none has-[:focus]:ring-1 ring-offset-1 ring-offset-white ring-zinc-600 transition duration-150 leading-normal has-[:focus]:bg-white shadow overflow-hidden">
                    <textarea
                      id="description"
                      name="description"
                      required=""
                      placeholder="e.g. A form to collect feedback from my customers."
                      rows="1"
                      className="p-2 px-3 resize-none w-full focus:outline-none bg-transparent max-h-[12rem] placeholder:text-zinc-500"
                    ></textarea>
                    <button
                      disabled=""
                      type="button"
                      className="flex items-center justify-center w-full gap-2 px-3 py-2 font-medium transition duration-150 border rounded-lg text-zinc-500 hover:text-black bg-zinc-50 border-zinc-200 hover:bg-zinc-100 false disabled:pointer-events-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        data-slot="icon"
                        className={`h-3 w-3   -ml-4 ${
                          formType === "dynamic"
                            ? "text-purple-500"
                            : "text-blue-600"
                        }`}
                      >
                        <path d="M15.98 1.804a1 1 0 0 0-1.96 0l-.24 1.192a1 1 0 0 1-.784.785l-1.192.238a1 1 0 0 0 0 1.962l1.192.238a1 1 0 0 1 .785.785l.238 1.192a1 1 0 0 0 1.962 0l.238-1.192a1 1 0 0 1 .785-.785l1.192-.238a1 1 0 0 0 0-1.962l-1.192-.238a1 1 0 0 1-.785-.785l-.238-1.192ZM6.949 5.684a1 1 0 0 0-1.898 0l-.683 2.051a1 1 0 0 1-.633.633l-2.051.683a1 1 0 0 0 0 1.898l2.051.684a1 1 0 0 1 .633.632l.683 2.051a1 1 0 0 0 1.898 0l.683-2.051a1 1 0 0 1 .633-.633l2.051-.683a1 1 0 0 0 0-1.898l-2.051-.683a1 1 0 0 1-.633-.633L6.95 5.684ZM13.949 13.684a1 1 0 0 0-1.898 0l-.184.551a1 1 0 0 1-.632.633l-.551.183a1 1 0 0 0 0 1.898l.551.183a1 1 0 0 1 .633.633l.183.551a1 1 0 0 0 1.898 0l.184-.551a1 1 0 0 1 .632-.633l.551-.183a1 1 0 0 0 0-1.898l-.551-.184a1 1 0 0 1-.633-.632l-.183-.551Z"></path>
                      </svg>
                      <p className="text-xs">Generate prompt</p>
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1.5">
              <button
                className="inline-flex items-center justify-center h-10 px-4 py-2 text-xs font-medium transition rounded-lg whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2  focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#191B33] text-zinc-50 "
                onClick={() => themeCreate(formType)}
                disabled={formName === "" || formType === "dynamic"}
              >
                {spin ? (
                  <Spin
                    indicator={<LoadingOutlined spin />}
                    spinning={spin}
                    className="text-white "
                  />
                ) : formType === "dynamic" ? (
                  "✨ Create Dynamic Form"
                ) : (
                  "Create Form"
                )}
              </button>

              <button
                className="inline-flex items-center justify-center h-10 px-4 py-2 text-xs font-medium transition rounded-lg whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-zinc-100 hover:text-zinc-900"
                onClick={handleCreateCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default DashboardLayout;

const API_VERSION: any = process.env.REACT_APP_API_VERSION;
let BACKEND_URL: string = process.env.REACT_APP_SERVICE_URL + API_VERSION;

// if backend_url has a trailing slash, remove it
if (BACKEND_URL.charAt(BACKEND_URL.length - 1) === "/") {
  BACKEND_URL = BACKEND_URL.slice(0, -1);
}

export const ENDPOINTS = {
  forms: {
    getOne: (form_id: string) => `${BACKEND_URL}/forms/${form_id}`,
    getList: (workspace_id: string) =>
      `${BACKEND_URL}/forms/?workspace_id=${workspace_id}`,
    createOne: `${BACKEND_URL}/forms/`,
    deleteOne: (form_id: string) => `${BACKEND_URL}/forms/${form_id}`,
    updateOne: (form_id: string) => `${BACKEND_URL}/forms/${form_id}`,
    getFormResponses: (form_id: string) =>
      `${BACKEND_URL}/responses/forms/${form_id}`,
    deleteFormResponse: (form_id: string) =>
      `${BACKEND_URL}/responses/${form_id}`,
    exportFormResponses: (form_id: string) =>
      `${BACKEND_URL}/export/responses/?form_url=${form_id}`,
  },
  aiForms: {
    createOne: `${BACKEND_URL}/ai_form/forms/`,
  },
  responses: {
    createOne: `${BACKEND_URL}/responses/`,
    updateOne: (response_id: string) =>
      `${BACKEND_URL}/responses/${response_id}`,
    getResponses: (form_id: string) => `${BACKEND_URL}/responses/`,
  },
  files: {
    upload: (theme_id: any) =>
      `${BACKEND_URL}/files/upload?theme_id=${theme_id}`,
    getFiles: (file_id: string) => `${BACKEND_URL}/files/`,
  },
  workspace: {
    createOne: `${BACKEND_URL}/workspaces/`,
    getList: `${BACKEND_URL}/workspaces/`,
    deleteOne: (worksapce_id: string) =>
      ` ${BACKEND_URL}/workspaces/${worksapce_id}`,
    updateOne: (worksapce_id: string) =>
      ` ${BACKEND_URL}/workspaces/${worksapce_id}`,
  },
  utils: {
    getCountries: `${BACKEND_URL}/utils/`,
  },
  theme: {
    createOne: `${BACKEND_URL}/themes/`,
    getAtheme: (theme_id: string) => `${BACKEND_URL}/${theme_id}`,
    updateAtheme: (theme_id: string) => `${BACKEND_URL}/themes/${theme_id}`,
    deleteAtheme: (theme_id: string) => `${BACKEND_URL}/${theme_id}`,
  },
  Dashboard: {
    getStats: (workspace_id: string, time_interval: string) =>
      `${BACKEND_URL}/dashboard/workspace-stats?workspace_id=${workspace_id}&filter=${time_interval}`,
  },
};

import React, { useState } from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { message } from "antd";

const ShareTab = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { form } = useFormEditorContext();

  const publicUrl = process.env.REACT_APP_PUBLISHED_URL + "forms/";

  const urlEndPoint = `${publicUrl}${form?.url_id}`;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(urlEndPoint).then(
      () => {
        messageApi.success("Link copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const [copySuccess, setCopySuccess] = useState("");

  const iframeCode = `<iframe src=${publicUrl}${form?.url_id}
    title="Embedded Form"
    width="100%"
    height="600px"
    style="border: none;"
  ></iframe>`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(iframeCode).then(
      () => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000); // Clear the success message after 2 seconds
      },
      () => {
        setCopySuccess("Failed to copy!");
      }
    );
  };

  return (
    <>
      {contextHolder}
      <div className="pb-4 mt-6 border-b border-zinc-200">
        <div className="flex items-center gap-1 mb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-slot="icon"
            className="w-3 h-3 text-zinc-500"
          >
            <path d="M12.232 4.232a2.5 2.5 0 0 1 3.536 3.536l-1.225 1.224a.75.75 0 0 0 1.061 1.06l1.224-1.224a4 4 0 0 0-5.656-5.656l-3 3a4 4 0 0 0 .225 5.865.75.75 0 0 0 .977-1.138 2.5 2.5 0 0 1-.142-3.667l3-3Z"></path>
            <path d="M11.603 7.963a.75.75 0 0 0-.977 1.138 2.5 2.5 0 0 1 .142 3.667l-3 3a2.5 2.5 0 0 1-3.536-3.536l1.225-1.224a.75.75 0 0 0-1.061-1.06l-1.224 1.224a4 4 0 1 0 5.656 5.656l3-3a4 4 0 0 0-.225-5.865Z"></path>
          </svg>
          <h4 className="text-sm font-semibold">Link</h4>
        </div>
        <p className="text-xs text-zinc-500">The public link of this form</p>
        <div className="p-1 mt-3 overflow-hidden border rounded-lg shadow bg-zinc-50 border-zinc-200">
          <p className="px-3 py-3 pt-2 font-mono text-xs">{urlEndPoint}</p>
          <button
            className="inline-flex items-center justify-center w-full h-8 px-3 text-xs font-medium transition rounded-lg shadow whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#191B33] text-zinc-50 hover:bg-zinc-700"
            onClick={handleCopyClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-3 h-3 mr-2"
            >
              <path d="M7 3.5A1.5 1.5 0 0 1 8.5 2h3.879a1.5 1.5 0 0 1 1.06.44l3.122 3.12A1.5 1.5 0 0 1 17 6.622V12.5a1.5 1.5 0 0 1-1.5 1.5h-1v-3.379a3 3 0 0 0-.879-2.121L10.5 5.379A3 3 0 0 0 8.379 4.5H7v-1Z"></path>
              <path d="M4.5 6A1.5 1.5 0 0 0 3 7.5v9A1.5 1.5 0 0 0 4.5 18h7a1.5 1.5 0 0 0 1.5-1.5v-5.879a1.5 1.5 0 0 0-.44-1.06L9.44 6.439A1.5 1.5 0 0 0 8.378 6H4.5Z"></path>
            </svg>{" "}
            Copy Link
          </button>
        </div>
        <div
          style={{
            padding: "0.25rem",
            paddingTop: "0",
            marginTop: "0.75rem",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fafafa",
            border: "1px solid #e2e8f0",
            borderRadius: "0.375rem",
            overflow: "hidden",
            fontSize: "0.75rem",
          }}
        >
          <code
            style={{
              display: "block",
              whiteSpace: "pre-wrap",
              backgroundColor: "transparent",
              color: "#383A42",
              fontFamily:
                '"Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono", monospace',
              textAlign: "left",
              padding: "1.5rem",
            }}
          >
            {iframeCode}
          </code>
          <button
            onClick={copyToClipboard}
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
              fontWeight: "500",
              transition: "all 0.2s",
              backgroundColor: "#1a202c",
              color: "#fafafa",
              borderRadius: "0.375rem",
              padding: "0.5rem 1rem",
              width: "100%",
              fontSize: "0.75rem",
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
              height: "2rem",
              cursor: "pointer",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              style={{
                height: "0.75rem",
                width: "0.75rem",
                marginRight: "0.5rem",
              }}
            >
              <path d="M7 3.5A1.5 1.5 0 0 1 8.5 2h3.879a1.5 1.5 0 0 1 1.06.44l3.122 3.12A1.5 1.5 0 0 1 17 6.622V12.5a1.5 1.5 0 0 1-1.5 1.5h-1v-3.379a3 3 0 0 0-.879-2.121L10.5 5.379A3 3 0 0 0 8.379 4.5H7v-1Z"></path>
              <path d="M4.5 6A1.5 1.5 0 0 0 3 7.5v9A1.5 1.5 0 0 0 4.5 18h7a1.5 1.5 0 0 0 1.5-1.5v-5.879a1.5 1.5 0 0 0-.44-1.06L9.44 6.439A1.5 1.5 0 0 0 8.378 6H4.5Z"></path>
            </svg>
            Copy Full Page Embed Code
          </button>
          {copySuccess && (
            <div style={{ color: "#38a169", marginTop: "0.5rem" }}>
              {copySuccess}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShareTab;

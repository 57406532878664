import React from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { FieldSettingEditor } from "../FormEditor/Fields/FieldSetting";
import {
  FIELD_TYPE_SETTINGS,
  QUESTION_TYPES,
} from "../../Constants/questionTypes";
import { motion, AnimatePresence } from "framer-motion";
import { sideFade } from "../../Constants/animations";
import WelcomeScreenSettings from "./WelcomeScreenSettings";
import EndScreenSettings from "./EndScreenSettings";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";
import { message } from "antd";
import { useDispatch } from "react-redux";

const FieldSettingsSider = () => {
  const {
    fields,
    setFields,
    selectedField,
    setSelectedField,
    setEditorField,
    editorField,
    welcomeScreen,
    setWelcomeScreen,
    setChanged,
    setIsPreview,
    form,
    theme,
  } = useFormEditorContext();

  const api = useApi();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const generateRandomName = (extension) => {
    const randomString = Math.random().toString(36).substring(2, 15);
    const timestamp = Date.now();
    return `${randomString}_${timestamp}.${extension}`;
  };

  const base64ToBlob = (base64, mimeType) => {
    const base64WithoutPrefix = base64.split(",")[1];
    const binaryString = atob(base64WithoutPrefix);
    const byteNumbers = new Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    const extension = mimeType.split("/")[1];
    const randomName = generateRandomName(extension);

    return { blob, randomName };
  };

  const onSave = ({ isai = false }) => {
    setChanged(true);
    const newFields = [...fields];
    const index = newFields.findIndex((f) => f?.id === selectedField?.id);

    const updatedEditorField = {
      ...editorField,
      title: editorField?.title || "",
      properties: {
        ...editorField.properties,
        description: editorField.properties?.description || "",
      },
    };
    const cleanEditorField = Object.fromEntries(
      Object.entries(updatedEditorField).filter(([key, value]) => value != null)
    );

    newFields[index] = cleanEditorField;
    setFields(newFields);

    const isHavingAttachment = editorField?.attachment?.link?.startsWith(
      "data:image"
    )
      ? true
      : false;

    if (
      selectedField === "welcome-screen" &&
      welcomeScreen?.layout?.attachment?.href?.startsWith("data:image")
    ) {
      const mimeType = "image/png";
      const { blob, randomName } = base64ToBlob(
        welcomeScreen?.layout?.attachment?.href,
        mimeType
      );

      const formData = new FormData();
      formData.append("file", blob, randomName);
      uploadMutation.mutate(formData);
    }

    //new
    if (isHavingAttachment) {
      const mimeType = "image/png";
      const { blob, randomName } = base64ToBlob(
        editorField?.attachment?.link,
        mimeType
      );

      const formData = new FormData();
      formData.append("file", blob, randomName);
      uploadMutation.mutate(formData);
    }

    if (isai) {
      setIsPreview(true);
    } else {
      setSelectedField(null);
    }
  };

  const uploadMutation = useMutation({
    mutationFn: (form) => api.files.uploadFile(form, theme?._id),
    onSuccess: (data) => {
      console.log(data);
      console.log("image uploaded successfully");
      const url = data?.data?.url;

      if (selectedField === "welcome-screen") {
        const updatedWelcomeScreen = {
          ...welcomeScreen,
          layout: {
            ...welcomeScreen.layout,
            attachment: {
              ...welcomeScreen.layout.attachment,
              href: url,
            },
          },
        };

        setWelcomeScreen(updatedWelcomeScreen);

        const newFields = [...fields];
        const index = newFields.findIndex((f) => f?.id === selectedField?.id);
        newFields[index] = updatedWelcomeScreen;
        setFields(newFields);
        setSelectedField(null);
        //console.log("welcome screen : ", welcomeScreen);
      } else {
        // Update the attachment link for other selected fields
        const newFields = [...fields];
        const index = newFields.findIndex((f) => f?.id === selectedField?.id);

        if (index !== -1) {
          const updatedField = {
            ...newFields[index],
            attachment: {
              ...newFields[index].attachment,
              link: url,
              type: "image",
            },
          };
          newFields[index] = updatedField;
          setFields(newFields);
          setSelectedField(null);
          //console.log("updated field : ", updatedField);
        }
      }
    },
    onError: (error) => {
      console.log("Could not upload image");
    },
  });

  const fieldTypeSettings = FIELD_TYPE_SETTINGS[selectedField?.type];

  const renderSettings = (settings, parentKey = "") => {
    if (!settings) return null;

    return Object.keys(settings).map((key) => {
      const value = settings[key];
      const fullKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === "object" && !Array.isArray(value)) {
        return <div key={fullKey}>{renderSettings(value, fullKey)}</div>;
      }

      return (
        <FieldSettingEditor
          key={fullKey}
          type={value}
          label={fullKey}
          setEditorField={setEditorField}
          editorField={editorField}
        />
      );
    });
  };

  const handleClose = () => {
    if (!editorField?.title) {
      onSave({ isai: false });
      setSelectedField(null);
    }
    setSelectedField(null);
  };

  if (selectedField) {
    return (
      <main className="relative h-screen overflow-y-auto">
        {contextHolder}
        <AnimatePresence mode="wait">
          <motion.div {...sideFade}>
            <div className="w-[20rem] h-full bg-white px-5 pt-4 flex flex-col relative">
              {/* breadcrumb */}
              <div className="flex items-center justify-between mb-6">
                <div className="flex flex-col ">
                  <div className="flex items-center gap-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      data-slot="icon"
                      class="h-4 w-4"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.84 1.804A1 1 0 0 1 8.82 1h2.36a1 1 0 0 1 .98.804l.331 1.652a6.993 6.993 0 0 1 1.929 1.115l1.598-.54a1 1 0 0 1 1.186.447l1.18 2.044a1 1 0 0 1-.205 1.251l-1.267 1.113a7.047 7.047 0 0 1 0 2.228l1.267 1.113a1 1 0 0 1 .206 1.25l-1.18 2.045a1 1 0 0 1-1.187.447l-1.598-.54a6.993 6.993 0 0 1-1.929 1.115l-.33 1.652a1 1 0 0 1-.98.804H8.82a1 1 0 0 1-.98-.804l-.331-1.652a6.993 6.993 0 0 1-1.929-1.115l-1.598.54a1 1 0 0 1-1.186-.447l-1.18-2.044a1 1 0 0 1 .205-1.251l1.267-1.114a7.05 7.05 0 0 1 0-2.227L1.821 7.773a1 1 0 0 1-.206-1.25l1.18-2.045a1 1 0 0 1 1.187-.447l1.598.54A6.992 6.992 0 0 1 7.51 3.456l.33-1.652ZM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <h4 className="text-sm font-semibold">Settings</h4>
                  </div>
                  <p className="text-xs font-medium text-gray-400 text-start">
                    {selectedField?.label}{" "}
                  </p>
                </div>
                <button
                  onClick={() => handleClose()}
                  className="p-2 transition-all duration-200 ease-in-out bg-white border border-gray-300 rounded-lg ring-black border-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 animate-scale-up-sm"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 16 17"
                    fill="#737373"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59646 8.5L0 2.90354L2.40354 0.5L8 6.09646L13.5965 0.5L16 2.90354L10.4035 8.5L16 14.0965L13.5965 16.5L8 10.9035L2.40354 16.5L0 14.0965L5.59646 8.5Z"
                    ></path>
                  </svg>
                </button>
              </div>

              <div className="flex flex-col gap-4 ">
                {renderSettings(fieldTypeSettings)}
                {selectedField === "welcome-screen" ? (
                  <WelcomeScreenSettings />
                ) : null}
                {selectedField === "end-screen" ? <EndScreenSettings /> : null}
              </div>
              {editorField?.type === QUESTION_TYPES.AI_STATEMENT && (
                <div>
                  <button
                    className="whitespace-nowrap mt-8 ring-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50  disabled:bg-white bg-white border border-black hover:bg-white transition duration-150 rounded-lg py-2.5 px-2 w-full text-black text-xs font-medium flex items-center justify-center h-9"
                    onClick={() => onSave({ isai: true })}
                  >
                    ✨ Preview
                  </button>
                </div>
              )}
              <div className="flex items-center justify-center w-full gap-4 mt-4 ">
                <button
                  className="whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50    disabled:bg-zinc-700 bg-black hover:bg-zinc-700 transition duration-150 rounded-lg py-2.5 px-2 w-full text-white text-xs font-medium flex items-center justify-center h-9"
                  onClick={() => onSave({ isai: false })}
                >
                  Save
                </button>
                <button
                  class="flex items-center justify-center w-full p-2 text-xs font-medium rounded-lg hover:bg-rose-100 text-rose-500"
                  onClick={() => handleClose()}
                >
                  Discard
                </button>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </main>
    );
  }
  return null;
};

export default FieldSettingsSider;

import React from "react";
import { Button } from "antd";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";

export const FormSaveButton = () => {
  const { saveChanges } = useFormEditorContext();
  return (
    <button
      className="whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2  focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50     bg-[#191B33]  transition duration-150 rounded-lg py-2.5 px-2 w-full text-white text-xs font-medium flex items-center justify-center h-9"
      onClick={saveChanges}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
        data-slot="icon"
        className="w-3 h-3 mr-2"
      >
        <path d="M1 12.5A4.5 4.5 0 0 0 5.5 17H15a4 4 0 0 0 1.866-7.539 3.504 3.504 0 0 0-4.504-4.272A4.5 4.5 0 0 0 4.06 8.235 4.502 4.502 0 0 0 1 12.5Z"></path>
      </svg>
      Save &amp; Publish
    </button>
  );
};

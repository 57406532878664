import React, { useEffect } from "react";
import { Checkbox, Row, Col, Typography, Input } from "antd";
import { useFormContext } from "../../Contexts/FormContext";
import { Flex } from "antd";
import { motion, useAnimate } from "framer-motion";
import { getIndexForLetter, getLetterForIndex } from "../../Utils/string";
import tinycolor from "tinycolor2";
// import { useOnKeyPress } from "../../Hooks/handleKeyPress";
import KeyboardEventHandler from "react-keyboard-event-handler";
import {
  answerCheckboxTextSize,
  answerInputTextSize,
  getInputStyle,
  getSelectOptionBackgroundColor,
  getSelectOptionBorderColor,
} from "../../Constants/styles";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";

export const CheckBoxGroupInput = ({
  legalOptions,
  onChange,
  field,
  ...props
}) => {
  const { formInstance, styles, setShowNextButton, next } = useFormContext();

  // Ensure options are defined, default to an empty array if not
  //worked
  /*   const options = (field?.properties?.choices || []).map((o) => ({
    ...o,
    value: o?.label,
  })); */

  // Define options for legal question type or use field properties choices
  let options = [];
  const isAllowMultiple = field?.properties?.allow_multiple_selection;
  if (legalOptions) {
    options = [
      { id: 1, label: "I Agree", value: "agree" },
      { id: 2, label: "I Disagree", value: "disagree" },
    ];
  } else {
    options = (field?.properties?.choices || []).map((o) => ({
      ...o,
      value: o?.label,
    }));
  }

  const value = formInstance?.getFieldValue(field?.id);

  if (legalOptions) {
    if (options[value]?.value === "disagree") {
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
  }
  const multiple = field?.properties?.allow_multiple_selection ?? false;

  const color = styles.secondary_color;

  // Define options for legal question type

  const span =
    options.filter((o) => o.value.length > 24).length > 0
      ? 24
      : // : options.filter((o) => o.value.length > 12).length > 0
        // ? 12
        8;

  const Item = ({ children }) => (
    <Col
      span={span}
      md={span}
      sm={24}
      xs={24}
      style={{
        alignItems: "start",
        display: "flex",
        // padding: 3,
        // backgroundColor: 'red',
        justifyContent: "flex-start",
      }}
    >
      {children}
    </Col>
  );

  const onClick = (option) => {
    const selected = value?.includes(option);
    console.log("onclick", typeof option == "object");
    const _value =
      typeof option == "object"
        ? value?.filter((v) => typeof v !== "object")
        : value;
    // const value = option.id;
    if (!multiple || legalOptions) {
      // when its not a multiple select
      if (selected) {
        onChange([]);
      } else {
        onChange([option]);
      }
    } else {
      //case when a multiple select
      if (selected) {
        onChange([..._value.filter((v) => v !== option)]);
      } else if (!value) {
        onChange([option]);
      } else {
        onChange([..._value, option]);
      }
    }
    //console.log("value : ", value);
    if (value && !isAllowMultiple) {
      next();
    }
  };

  return (
    <>
      <Checkbox.Group
        style={{ width: "100%", border: "none" }}
        {...props}
        // onChange={(value) => {
        //   console.log({value})
        //   formInstance?.setFieldValue(field.id, value[0]);
        // }}
        //   onChange={(value) => {
        //     if (!multiple) {
        //       onChange([value[value.length - 1]]);
        //     } else {
        //       onChange(value);
        //     }
        //     console.log({ value });
        //   }}
      >
        <Row gutter={[8, 8]}>
          {options.map((option, index) => {
            const selected = value?.includes(option.id);
            return (
              <Item key={index}>
                <CheckBoxGroupItem
                  {...{
                    option,
                    color,
                    selected,
                    multiple,
                    // onChange,
                    index,
                    value,
                    onClick,
                  }}
                />

                {/* <Checkbox
                value={option.id}
                style={{
                  border: `1.5px solid ${styles.primary_color}`,
                  borderRadius: 10,
                  padding: 2,
                  width: "100%",
                  height: "100%",
                }}
                onClick={(value) => {
                  if (!multiple) {
                    onChange([value[value.length - 1]]);
                  } else {
                    onChange(option.id);
                  }
                  console.log({ value });
                }}
              >
                <Flex>
                  <div
                    // className="fill-available"
                    style={{
                      backgroundColor: "red",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <Typography.Text>
                      {getLetterForIndex(index)}
                    </Typography.Text>
                  </div>
                  {option.value}
                </Flex>
              </Checkbox> */}
                {/* </motion.div> */}
              </Item>
            );
          })}
          {field?.properties?.allow_other_choice && (
            <Item key={"fmkdlsaf"}>
              <CheckBoxGroupOtherItem
                index={options?.length}
                color={color}
                onChange={onChange}
                multiple={multiple}
                value={value}
                focused={focus}
                onClick={onClick}
              />
            </Item>
          )}
          {/* {JSON.stringify(value)} */}
        </Row>
      </Checkbox.Group>
    </>
  );
};

const CheckBoxGroupOtherItem = ({
  index,
  color,
  multiple,
  value,
  onClick,
  onChange,
}) => {
  const { styles } = useFormContext();
  const [scope, animate] = useAnimate();
  const [selected, setSelected] = React.useState(false);
  const otherValueObj = value?.find((v) => typeof v === "object");
  const [otherValue, setOtherValue] = React.useState(
    otherValueObj?.other ?? ""
  );

  const { isSubQuestion } = useFormContext();
  const focused = !isSubQuestion[0] || index === isSubQuestion[1];

  const letter = getLetterForIndex(index);

  // const setOtherValue = (val) => {
  //   const index = value?.findIndex((v) => typeof v === "object");
  //   if (index == -1) {
  //     onClick({ other: val });
  //   } else {
  //     const _value = value;
  //     _value[index] = { other: val };
  //     onChange(_value);
  //   }
  // };

  const onEnter = () => {
    onClick({ other: otherValue });
  };

  // const otherValue = otherValueObj["other"];

  const animation = async () => {
    console.log("animation");
    await animate(scope.current, {
      type: "tween",
      scale: [1.02, 1, 1.02, 1],
      times: [0.2, 0.1, 0.2, 0.1],
    });
  };

  const _onClick = () => {
    setSelected(!selected);
  };

  // console.log("selected selected", value);
  return (
    <motion.div
      ref={scope}
      key={"other"}
      style={{
        width: "100%",
        height: "95%",
        display: "flex",
        //   borderRadius: 10,
      }}
      whileTap={{ scale: 0.98 }}
      whileHover={{ scale: 1.01 }}
    >
      <KeyboardEventHandler
        handleKeys={[letter.toLowerCase()]}
        onKeyEvent={async (key, e) => {
          //   console.log({ key });
          if (focused) {
            await animation();
            _onClick();
          }
        }}
      />
      <OptionFormat
        color={color}
        letter={letter}
        selected={selected || otherValue}
        onClick={_onClick}
        textRender={
          selected ? (
            <>
              <KeyboardEventHandler
                handleKeys={["enter", "esc"]}
                onKeyEvent={async (key, e) => {
                  //   console.log({ key });
                  if (focused) {
                    if (key === "enter") {
                      onEnter();
                      setSelected(false);
                    } else if (key === "esc") {
                      setOtherValue("");
                      setSelected(false);
                      onClick({ other: null });
                    }
                  }
                }}
              >
                <Input
                  style={getInputStyle(styles, true)}
                  autoFocus={selected}
                  placeholder="Type here"
                  value={otherValue}
                  onChange={(e) => setOtherValue(e.target.value)}
                  size={"small"}
                />
              </KeyboardEventHandler>
            </>
          ) : (
            <Typography.Text
              style={{
                textAlign: "justify",
                color: color,
                fontSize: answerCheckboxTextSize,
              }}
            >
              {otherValue ? otherValue : "Other"}
            </Typography.Text>
          )
        }
      />
      {/* <Flex
        style={{
          border: `1px solid #fff`,
          borderWidth: selected ? 1 : 1,
          borderColor: getSelectOptionBorderColor(color),
          borderRadius: 5,
          padding: "2px 5px 2px 5px",
          width: "100%",
          height: "100%",
          alignItems: "center",
          backgroundColor: getSelectOptionBackgroundColor(
            color,
            selected || otherValue
          ),
        }}
        onClick={_onClick}
        gap={"middle"}
      >
        <Flex
          style={{
            border: `1.5px solid ${color}`,
            backgroundColor:
              selected || otherValue
                ? tinycolor(color).setAlpha(0.9).toRgbString()
                : "white",
            borderColor:
              // selected
              // ? tinycolor(color).lighten(30).toRgbString()
              // :
              color,
            borderRadius: 3,
            padding: "0 5px 0 5px",
            alignItems: "left",
            justifyContent: "flex-end",
            textAlign: "left",
          }}
        >
          <Typography.Text
            style={{ color: selected || otherValue ? "white" : color }}
          >
            {letter}
          </Typography.Text>
        </Flex>
        {selected ? (
          <>
            <KeyboardEventHandler
              handleKeys={["enter", "esc"]}
              onKeyEvent={async (key, e) => {
                console.log({ key });
                if (focused) {
                  if (key === "enter") {
                    onEnter();
                    setSelected(false);
                  } else if (key === "esc") {
                    setOtherValue("");
                    setSelected(false);
                    onClick({ other: null });
                  }
                }
              }}
            >
              <Input
                style={getInputStyle(styles, true)}
                autoFocus={selected}
                placeholder="Type here"
                value={otherValue}
                onChange={(e) => setOtherValue(e.target.value)}
                size={"small"}
              />
            </KeyboardEventHandler>
          </>
        ) : (
          <Typography.Text
            style={{
              textAlign: "justify",
              color: color,
              fontSize: answerCheckboxTextSize,
            }}
          >
            {otherValue ? otherValue : "Other"}
          </Typography.Text>
        )}
      </Flex> */}
    </motion.div>
  );
};

function CheckBoxGroupItem({
  option,
  color,
  selected,
  multiple,
  onChange,
  index,
  value,
  onClick,
}) {
  const [scope, animate] = useAnimate();
  const { isSubQuestion } = useFormContext();
  const focused = !isSubQuestion[0] || index === isSubQuestion[1];

  const animation = async () => {
    console.log("animation for the select : ");
    await animate(scope.current, {
      type: "tween",
      scale: [1.02, 1, 1.02, 1],
      times: [0.1, 0.05, 0.1, 0.05],
    });
  };

  //   let initial = false;
  //   useEffect(() => {
  //     if (initial) return;
  //     initial = true;
  //     window.addEventListener("keydown", (e) => {
  //       console.log(e.key);
  //       if (getIndexForLetter(e.key) === index) {
  //         animation();
  //       }
  //     });
  //     return () => window.removeEventListener("keydown", () => {});
  //   }, []);

  // useOnKeyPress(animation, getLetterForIndex(index));

  const letter = getLetterForIndex(index);
  const { formTheme } = useFormContext();
  const { theme } = useFormEditorContext();
  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;

  return (
    <motion.div
      ref={scope}
      key={option.id}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        //   borderRadius: 10,
      }}
      whileTap={{ scale: 0.98 }}
      whileHover={{ scale: 1.01 }}
    >
      <KeyboardEventHandler
        handleKeys={[letter.toLowerCase()]}
        onKeyEvent={async (key, e) => {
          console.log({ isSubQuestion, index, focused });
          if (focused) {
            await animation();
            onClick(option.id);
          }
        }}
      />
      <OptionFormat
        color={color}
        // option={option}
        letter={letter}
        selected={selected}
        onClick={() => onClick(option.id)}
        textRender={
          <Typography.Text
            style={{
              textAlign: "start",
              color: constTheme?.button_color,
              fontSize: answerCheckboxTextSize,
              alignContent: "top",
              // backgroundColor: 'red',

              lineHeight: "1.5em",

              // backgroundColor: 'green'
            }}
          >
            {option.value}
          </Typography.Text>
        }
      />
    </motion.div>
  );
}

const OptionFormat = ({ color, textRender, letter, selected, onClick }) => {
  const { formTheme } = useFormContext();
  const { theme } = useFormEditorContext();
  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;
  return (
    <Flex
      key={"other"}
      style={{
        border: `1px solid #fff`,
        borderWidth: selected ? 1 : 1,
        borderColor: getSelectOptionBorderColor(constTheme?.button_color),
        borderRadius: 5,
        // borderColor: "red",
        padding: "8px",

        width: "100%",
        // height: "100%",
        alignItems: "top",
        backgroundColor: getSelectOptionBackgroundColor(
          constTheme?.button_color,
          selected
        ),
        // backgroundColor: 'red'
      }}
      onClick={onClick}
      gap={"middle"}
    >
      <Flex
        style={{
          border: `1px solid #fff`,
          borderWidth: 1,
          backgroundColor: selected
            ? tinycolor(constTheme?.button_color).setAlpha(0.9).toRgbString()
            : "white",
          borderColor:
            // selected
            // ? tinycolor(color).lighten(30).toRgbString()
            // :
            getSelectOptionBorderColor(constTheme?.button_color),

          // backgroundColor: 'blue',
          borderRadius: 3,
          // padding: "5px 5px 5px 5px",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "left",
          minWidth: "2em",
          height: "2em",
        }}
      >
        <Typography.Text
          style={{
            color: selected ? constTheme?.text_color : constTheme?.button_color,
            lineHeight: "1.25em",
            fontWeight: "bold",
          }}
        >
          {letter}
        </Typography.Text>
      </Flex>
      {textRender}
    </Flex>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";
import {
  QuestionViewFormat,
  QuestionViewContentFormat,
} from "../../../Views/QuestionView.tsx";
import { FormNextButton } from "../../Form/FormNextButton.jsx";
import { Question, QuestionFormat } from "../../Form/Question";
import {
  questionTitleStyle,
  questionDescriptionStyle,
} from "../../../Constants/styles";
import AnswerSection from "../../Form/AnswerSection.tsx";
import { updateForm } from "../../../Redux/slices/formSlice.tsx";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { QUESTION_TYPES } from "../../../Constants/questionTypes.js";

const SOCKET_SERVER_URL = process.env.REACT_APP_SERVICE_URL;
const SOCKET_PATH = "/statements";

export const QuestionEditor = () => {
  const {
    editorField,
    setEditorField,
    fields,
    form,
    theme,
    isPreview,
    setIsPreview,
  } = useFormEditorContext();

  const updateField = (key, subkey = null, value) => {
    setEditorField((prev) => {
      if (subkey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [subkey]: value,
          },
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
  };

  if (!editorField) return null; // Render nothing if no field is selected

  const index = fields.findIndex((f) => f.id === editorField.id);

  if (editorField?.type === QUESTION_TYPES.AI_STATEMENT) {
    const socketRef = useRef(null);
    const [textArray, setTextArray] = useState([]);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    isCompleted ?? setIsPreview(false);

    useEffect(() => {
      socketRef.current = io(SOCKET_SERVER_URL, {
        path: SOCKET_PATH,
      });

      const socket = socketRef.current;

      socket.on("connect", () => {
        setIsConnected(true);
        setIsCompleted(false);
        setError(null);
        //   console.log("Connected to server");
      });

      socket.on("statement", (data) => {
        setIsLoading(false);
        setTextArray((prevArray) => [...prevArray, data?.statement]);
        setIsCompleted(data?.is_completed);
        if (data?.is_completed || !isConnected) {
          setIsPreview(false);
        }
      });

      socket.on("disconnect", () => {
        setIsConnected(false);
        setIsCompleted(false);
        //  console.log("Disconnected from server");
      });

      socket.on("connect_error", (err) => {
        setError("Connection error");
        console.error("Connection error: ", err);
      });

      socket.on("connect_timeout", () => {
        setError("Connection timeout");
        console.error("Connection timeout");
      });

      socket.on("error", (err) => {
        setError("An error occurred");
        console.error("Error: ", err);
      });

      return () => {
        socket.disconnect();
      };
    }, [editorField?.id]);

    const emitPreviewStatement = () => {
      setIsLoading(true);
      setTextArray([]);
      const updateFormLocal = { ...form, fields };
      dispatch(updateForm(updateFormLocal));
      // console.log("form to be send : ", updateFormLocal);
      socketRef.current.emit("preview_statement", {
        form: updateFormLocal,
        field_id: editorField?.id,
      });
    };
    useEffect(() => {
      if (isPreview) {
        emitPreviewStatement();
      }
    }, [isPreview, editorField?.id]);

    return (
      <QuestionViewFormat
        index={index}
        secondary_color={form?.primary_color || form?.secondary_color}
        content={
          <QuestionViewContentFormat
            question={
              <QuestionFormat
                aiStatement={true}
                isLoading={isLoading}
                textArray={textArray}
              />
            }
          />
        }
      />
    );
  }

  const [placeHolderColor, setPlaceHolderColor] = useState(null);
  // Utility function to convert hex to RGB
  const hexToRgb = (hex) => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    // Parse the hex string
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return { r, g, b };
  };

  // Utility function to convert RGB to hex
  const rgbToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  };

  // New function to parse RGB string
  const parseRgb = (rgbString) => {
    const match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (match) {
      return {
        r: parseInt(match[1]),
        g: parseInt(match[2]),
        b: parseInt(match[3]),
      };
    }
    return null;
  };

  const getBrightness = (r, g, b) => {
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  // Function to add opacity to a color
  const addOpacity = (color, opacity) => {
    const rgb = typeof color === "string" ? parseRgb(color) : color;
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
  };

  // Function to get the negative color
  const getNegativeColor = (
    color,
    opacityThreshold = 50,
    opacityValue = 0.5
  ) => {
    let rgb;

    if (typeof color === "string") {
      if (color.startsWith("#")) {
        rgb = hexToRgb(color);
      } else if (color.startsWith("rgb")) {
        rgb = parseRgb(color);
      }
    } else if (
      typeof color === "object" &&
      "r" in color &&
      "g" in color &&
      "b" in color
    ) {
      rgb = color;
    }

    if (!rgb) {
      console.error("Invalid color format:", color);
      return "rgba(0, 0, 0, 0.5)"; // Default to semi-transparent black if invalid input
    }

    // Invert each component
    const invertedR = 255 - rgb.r;
    const invertedG = 255 - rgb.g;
    const invertedB = 255 - rgb.b;

    // Calculate brightness of the inverted color
    const brightness = getBrightness(invertedR, invertedG, invertedB);

    // If the inverted color is too dark, add opacity
    if (brightness < opacityThreshold) {
      return addOpacity(
        { r: invertedR, g: invertedG, b: invertedB },
        opacityValue
      );
    }

    // Otherwise, return the hex color
    return rgbToHex(invertedR, invertedG, invertedB);
  };

  useEffect(() => {
    const negativeColor = getNegativeColor(theme?.background_color);
    setPlaceHolderColor(negativeColor);
  }, [theme?.background_color]);

  return (
    <QuestionViewFormat
      index={index}
      secondary_color={form?.primary_color || form?.secondary_color}
      content={
        <QuestionViewContentFormat
          bottomButton={<FormNextButton index={index}>Next</FormNextButton>}
          question={
            <QuestionFormat
              title={
                <div>
                  <style>
                    {`
                    .dynamic-placeholder::placeholder {
                       color: ${placeHolderColor} !important;
                    }
                  `}
                  </style>
                  <Input
                    style={{
                      ...questionTitleStyle(),
                      color: theme?.text_color,

                      fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                    }}
                    size="large"
                    className="dynamic-placeholder"
                    variant="borderless"
                    placeholder={" Add question title"}
                    value={editorField?.title}
                    onChange={(e) => updateField("title", null, e.target.value)}
                  />
                </div>
              }
              description={
                <div>
                  <style>
                    {`
                  .dynamic-placeholder::placeholder {
                     color: ${placeHolderColor} !important;
                  }
                `}
                  </style>
                  <Input
                    variant="borderless"
                    placeholder="Add question description (optional)"
                    className="dynamic-placeholder"
                    value={editorField?.properties?.description}
                    onChange={(e) =>
                      updateField("properties", "description", e.target.value)
                    }
                    style={{
                      ...questionDescriptionStyle,
                      color: theme?.text_color,
                      fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                    }}
                  />
                </div>
              }
              attachment={editorField?.attachment?.link}
              placement={editorField?.attachment?.placement}
              answer={
                editorField?.properties?.fields?.length > 0 ? (
                  editorField?.properties?.fields?.map((field, index) => (
                    <Question
                      key={field?.id}
                      field={field}
                      isLastQuestion={false}
                      editor={true}
                    />
                  ))
                ) : (
                  <AnswerSection
                    field={editorField}
                    index={index}
                    editor={true}
                  />
                )
              }
            />
          }
        />
      }
    />
  );
};

/* import { Input, Flex } from "antd";
import { questionAnimation } from "../../../Constants/animations";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";
import { Question, QuestionFormat } from "../../Form/Question";
import { motion } from "framer-motion";
import {
  questionDescriptionStyle,
  questionTitleStyle,
} from "../../../Constants/styles";
import {
  QuestionViewContentFormat,
  QuestionViewFormat,
} from "../../../Views/QuestionView.tsx";
import { FormNextButton } from "../../Form/FormNextButton.jsx";
import { FormHeader, FormHeaderFormat } from "../../Form/FormHeader.jsx";

export const QuestionEditor = ({ selectedField }) => {
  const { setFields, fields, form } = useFormEditorContext();
  const newFields = [...fields];
  const index = newFields?.findIndex((f) => f?.id === selectedField?.id);

  const updateField = (field, key, subkey = null, value) => {
    if (subkey) {
      newFields[index][key] = newFields[index][key] || {};
      newFields[index][key][subkey] = value;
    } else {
      newFields[index][key] = value;
    }
    setFields(newFields);
  };

  console.log("selected field title :  ", selectedField?.title);

  return (
    // <motion.div
    //   key={JSON.stringify(selectedField)}

    // //   style={{backgroundColor: 'red'}}
    //   {...questionAnimation}
    // >
    // <Flex vertical style={{flex: 1, height:'auto'}}>
    //   <FormHeaderFormat
    //     showPercentage={selectedField == "welcome-screen"}
    //     percentage={((fields.length - index) / fields.length) * 100}
    //     strokeColor={"#fefe00"}
    //   />
    <QuestionViewFormat
      index={index}
      secondary_color={form?.secondary_color | form?.primary_color}
      content={
        <QuestionViewContentFormat
          bottomButton={<FormNextButton index={index}>Next</FormNextButton>}
          question={
            <QuestionFormat
              title={
                <Input
                  style={questionTitleStyle()}
                  size="large"
                  variant="borderless"
                  placeholder="Add question title"
                  value={selectedField?.title}
                  onChange={(e) =>
                    updateField(selectedField, "title", null, e.target.value)
                  }
                />
              }
              description={
                <Input
                  variant="borderless"
                  placeholder="Add question description (optional)"
                  value={selectedField?.properties?.description}
                  onChange={(e) =>
                    updateField(
                      selectedField,
                      "properties",
                      "description",
                      e.target.value
                    )
                  }
                  style={questionDescriptionStyle}
                />
              }
              answer={
                <div className="px-4 text-xs text-start text-zinc-500">
                  {" "}
                  answer
                </div>
              }
            />
          }
        />
      }
    />
    // </Flex>
  );
};
 */

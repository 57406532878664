import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Table, Segmented, Typography, message, Modal } from "antd";
import ContentTab from "../Components/MyEditor/ContentTab";
import { useParams } from "react-router-dom";
import { FormHeaderFormat } from "../Components/Form/FormHeader.jsx";
import RepliesTab from "../Components/MyEditor/RepliesTab";
import { questionAnimation } from "../Constants/animations";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../Api/useApi.ts";
import { useFormEditorContext } from "../Contexts/FormEditorContext.jsx";
import { setFormResponse } from "../Redux/slices/formSlice.tsx";
import { useDispatch, useSelector } from "react-redux";
import WelcomeScreenEditor from "../Components/FormEditor/Fields/WelcomeScreenEditor.jsx";
import { FormFooterFormat } from "../Components/Form/FormFooter.jsx";
import { EndScreenEditor } from "../Components/FormEditor/Fields/EndScreenEditor.jsx";
import { QuestionEditor } from "../Components/FormEditor/Fields/QuestionEditor.jsx";
import { selectForms } from "../Redux/selectors.jsx";
import { QuestionProvider } from "../Contexts/QuestionContext.jsx";
import FieldSettingsSider from "../Components/MyEditor/FieldSettingsSider.jsx";
import FormSettingsSider from "../Components/MyEditor/FormSettingsSider.jsx";
import ShareTab from "../Components/MyEditor/ShareTab.jsx";
import { useNavigate } from "react-router-dom";
import { convertBackToOldFormat } from "../Components/MyEditor/FormObjectConfig.jsx";
import { DeleteOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import DesignTab from "../Components/MyEditor/DesignTab.jsx";
import { FormView } from "../Views/FormView.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { ROUTES } from "../Constants/routes.js";
import CommonLayout from "./CommonLayout.jsx";

const items = [
  {
    key: "content",
    label: "Content",
    value: "content",
    children: <ContentTab ai={true} />,
  },
  {
    key: "design",
    label: "Design",
    value: "design",
    children: <DesignTab />,
  },

  {
    key: "share",
    label: "Share",
    value: "share",
    children: <ShareTab />,
  },
  {
    key: "replies",
    label: "Replies",
    value: "replies",
    children: <RepliesTab />,
  },
];

const AIFormEditorLayout = () => {
  const [alignValue, setAlignValue] = useState(items[0].value);
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  /* Form editor tools */
  const forms = useSelector(selectForms);

  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const { form, setForm, selectedField, fields, theme, name, changed } =
    useFormEditorContext();
  const index = fields.findIndex((field) => field?.id === selectedField?.id);

  const deleteMutation = useMutation({
    mutationFn: () => api.forms.deleteFormResponse(selectedReplyId),
    onSuccess: () => {
      message.success("Response deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["formResponses"] });
    },
    onError: (error) => {
      message.error("Could not delete response", error);
    },
  });

  useEffect(() => {
    if (id) {
      const form = forms.find((form) => form.id === id);
      const oldObj = convertBackToOldFormat(form);
      setForm(oldObj);
    }
  }, []);

  const dispatch = useDispatch();
  const selectedTab = items.find((item) => item.value === alignValue);

  /* form responses */
  const api = useApi();
  const { isFetching, data, isSuccess } = useQuery({
    queryKey: ["formResponses"],
    queryFn: () => api.forms.fetchFormResponses(form?.url_id),
    staleTime: Infinity,
    enabled: form?.url_id ? true : false,
  });

  useEffect(() => {
    if (isSuccess && data?.data) {
      dispatch(setFormResponse(data?.data));
    }
  }, [isSuccess, data, dispatch]);

  const allReplies = useSelector((state) => state.forms.responses);

  const replies = form.url_id ? allReplies : null;

  const [selectedReplyId, setSelectedReplyId] = useState(null);

  // Set the default selected reply when data is loaded
  useEffect(() => {
    if (replies?.responses?.length > 0 && selectedReplyId === null) {
      setSelectedReplyId(replies?.responses[0]?.response_id);
    }
  }, [replies, selectedReplyId]);

  const handleReplySelect = (replyId) => {
    setSelectedReplyId(replyId);
  };

  const answers = replies?.responses?.find(
    (reply) => reply?.response_id === selectedReplyId
  );

  /* If form ! */
  if (!form) return <Typography.Title>Form not found</Typography.Title>;
  const [formSettingsOpen, setFormSettingsOpen] = useState(false);

  const navigate = useNavigate();

  const url = "forms/";
  const publicUrl = process.env.REACT_APP_PUBLISHED_URL + url + form?.url_id;
  /* const publicUrl = `http://localhost:3000/` + url + form?.url_id; */

  /* tables  */
  const columns = [
    {
      title: "",
      dataIndex: "question",
      key: "question",
      width: "50%",
    },
    {
      title: "",
      dataIndex: "answer",
      key: "answer",
      width: "50%",
    },
  ];

  const dataSource = answers?.data?.map((item, index) => {
    const question = item?.question?.question_text;
    let answer = "";

    if (item.question.question_type === "legal") {
      answer = item.answer[0]?.answer_text === "[1]" ? "Agreed" : "Disagreed";
    } else {
      // General case for other questions
      answer = item.answer.map((ans) => ans?.answer_text).join(", ");
    }

    return {
      key: index,
      question,
      answer,
    };
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleIsChanged = () => {
    if (changed) {
      showModal();
    } else {
      navigate(ROUTES.DASHBOARD);
    }
  };

  const editorSidebar = (
    <>
      {selectedField && <FieldSettingsSider />}
      {formSettingsOpen && (
        <FormSettingsSider
          formSettingsOpen={formSettingsOpen}
          setFormSettingsOpen={setFormSettingsOpen}
        />
      )}
      <div className="w-[20rem] h-full bg-white px-5 pt-4 flex flex-col relative">
        {/* breadcrumb */}

        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-1">
            <button
              className="flex items-center text-xs font-medium transition duration-150 text-zinc-500 hover:text-blue-600"
              onClick={handleIsChanged}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                class="h-3 w-3 mr-1"
              >
                <path d="M10.362 1.093a.75.75 0 0 0-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925ZM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0 0 18 14.25V6.443ZM9.25 18.693v-8.25l-7.25-4v7.807a.75.75 0 0 0 .388.657l6.862 3.786Z"></path>
              </svg>{" "}
              Dashboard
            </button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              class="h-3 w-3"
            >
              <path
                fill-rule="evenodd"
                d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <p class="text-xs font-medium truncate max-w-[10rem]">{name}</p>
          </div>
          <button
            type="button"
            aria-haspopup="dialog"
            aria-expanded="false"
            aria-controls="radix-:r3t:"
            data-state="closed"
            class="inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium ring-offset-white transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50   hover:bg-zinc-100 hover:text-zinc-900   h-7 w-7"
            onClick={() => setFormSettingsOpen(!formSettingsOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              class="h-4 w-4"
            >
              <path
                fill-rule="evenodd"
                d="M7.84 1.804A1 1 0 0 1 8.82 1h2.36a1 1 0 0 1 .98.804l.331 1.652a6.993 6.993 0 0 1 1.929 1.115l1.598-.54a1 1 0 0 1 1.186.447l1.18 2.044a1 1 0 0 1-.205 1.251l-1.267 1.113a7.047 7.047 0 0 1 0 2.228l1.267 1.113a1 1 0 0 1 .206 1.25l-1.18 2.045a1 1 0 0 1-1.187.447l-1.598-.54a6.993 6.993 0 0 1-1.929 1.115l-.33 1.652a1 1 0 0 1-.98.804H8.82a1 1 0 0 1-.98-.804l-.331-1.652a6.993 6.993 0 0 1-1.929-1.115l-1.598.54a1 1 0 0 1-1.186-.447l-1.18-2.044a1 1 0 0 1 .205-1.251l1.267-1.114a7.05 7.05 0 0 1 0-2.227L1.821 7.773a1 1 0 0 1-.206-1.25l1.18-2.045a1 1 0 0 1 1.187-.447l1.598.54A6.992 6.992 0 0 1 7.51 3.456l.33-1.652ZM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        {/* Tabs */}
        <Segmented
          defaultValue={alignValue}
          onChange={setAlignValue}
          options={items.map((item) => ({
            label: item.label,
            value: item.value,
          }))}
        />
        <>
          {selectedTab.value === "replies" && (
            <>
              <RepliesTab
                replies={replies}
                onReplySelect={handleReplySelect}
                isFetching={isFetching}
                selectedReplyId={selectedReplyId}
                answers={answers}
              />
            </>
          )}
        </>
        {selectedTab.value === "content" && <ContentTab ai={true} />}
        {selectedTab.value === "share" && <ShareTab />}
        {selectedTab.value === "design" && <DesignTab />}
      </div>
    </>
  );

  return (
    <>
      <QuestionProvider question={index ? index : -1}>
        {contextHolder}
        <CommonLayout sidebar={editorSidebar}>
          {/* main section */}

          <div
            className={`relative flex justify-center h-full overflow-x-hidden  transition duration-200 border shadow-xl rounded-2xl border-zinc-200 ${
              selectedTab.value === "replies"
                ? "overflow-y-auto"
                : "overflow-y-hidden"
            }`}
            style={{
              backgroundColor:
                selectedTab.value === "replies"
                  ? "white"
                  : theme?.background_color,
            }}
          >
            <div className="w-full">
              {selectedTab.value === "content" && (
                <div className="flex flex-col h-screen">
                  <div>
                    {selectedField && (
                      <FormHeaderFormat
                        showPercentage={selectedField != "welcome-screen"}
                        percentage={((index + 1) / fields.length) * 100}
                        strokeColor={theme?.button_color || "rgb(208, 252, 3)"}
                      />
                    )}
                  </div>

                  <AnimatePresence mode="wait">
                    <div className="flex items-center justify-center w-full h-full ">
                      {selectedField == "welcome-screen" ||
                      selectedField === null ||
                      selectedField === "" ? (
                        <motion.div key="welcome-screen" {...questionAnimation}>
                          <WelcomeScreenEditor />
                        </motion.div>
                      ) : selectedField == "end-screen" ? (
                        <motion.div
                          key="end-screen"
                          {...questionAnimation}
                          className="w-full "
                        >
                          <EndScreenEditor />
                        </motion.div>
                      ) : (
                        <motion.div
                          key={selectedField?.id}
                          {...questionAnimation}
                        >
                          <QuestionEditor selectedField={selectedField} />
                        </motion.div>
                      )}
                    </div>
                  </AnimatePresence>
                  <div className="fixed flex items-end justify-end w-1/2 bottom-1 right-4">
                    {selectedField && (
                      <FormFooterFormat
                        showControls={selectedField != "welcome-screen"}
                      />
                    )}
                  </div>
                </div>
              )}
              {selectedTab.value === "replies" && (
                <div className="flex flex-col w-3/5 gap-4 mx-auto ">
                  {answers?.data.length >= 0 ? (
                    <>
                      <div className="flex justify-between ">
                        <div className="flex items-center gap-3 py-4 ">
                          <svg
                            width="42px"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.513463 11.172V11.1713C0.513463 9.4087 0.527533 7.86645 0.712563 6.52944C0.897 5.19673 1.24632 4.11188 1.88633 3.2478C3.15144 1.53976 5.70898 0.500002 11.1441 0.500002L20.759 0.5C26.1865 0.5 28.7722 1.4675 30.0659 3.14231C30.7183 3.98687 31.0829 5.06025 31.2797 6.40551C31.477 7.75518 31.5 9.3346 31.5 11.1713V20.8127C31.5 22.6298 31.479 24.2004 31.2843 25.5482C31.0902 26.8917 30.7288 27.9702 30.0784 28.822C28.7898 30.5097 26.208 31.5 20.7752 31.5H11.1576C5.59002 31.5 3.03469 30.5109 1.7966 28.838C1.17098 27.9927 0.839446 26.9202 0.670422 25.5775C0.500821 24.2302 0.5 22.663 0.5 20.845C0.5 20.8448 0.5 20.8447 0.5 20.8446L0.513463 11.172Z"
                              stroke="#B6B6B6"
                            ></path>
                            <path
                              d="M16 7C15.121 7 14.2617 7.26066 13.5308 7.74902C12.7999 8.23739 12.2303 8.93151 11.8939 9.74363C11.5575 10.5557 11.4695 11.4494 11.641 12.3115C11.8124 13.1737 12.2357 13.9656 12.8573 14.5871C13.4789 15.2087 14.2708 15.632 15.1329 15.8035C15.9951 15.975 16.8887 15.887 17.7008 15.5506C18.5129 15.2142 19.207 14.6445 19.6954 13.9136C20.1838 13.1828 20.4444 12.3235 20.4444 11.4444C20.4444 10.2657 19.9762 9.13524 19.1427 8.30175C18.3092 7.46825 17.1788 7 16 7ZM16 14.1111C15.4726 14.1111 14.957 13.9547 14.5185 13.6617C14.0799 13.3687 13.7382 12.9522 13.5363 12.4649C13.3345 11.9777 13.2817 11.4415 13.3846 10.9242C13.4875 10.4069 13.7414 9.93177 14.1144 9.55883C14.4873 9.18588 14.9625 8.93191 15.4798 8.82901C15.997 8.72612 16.5332 8.77893 17.0205 8.98076C17.5077 9.1826 17.9243 9.52439 18.2172 9.96292C18.5103 10.4015 18.6667 10.917 18.6667 11.4444C18.6667 12.1517 18.3857 12.83 17.8856 13.3301C17.3855 13.8302 16.7072 14.1111 16 14.1111ZM24 23.8889V23C24 21.3498 23.3444 19.7671 22.1775 18.6003C21.0107 17.4333 19.428 16.7778 17.7778 16.7778H14.2222C12.572 16.7778 10.9893 17.4333 9.82244 18.6003C8.65555 19.7671 8 21.3498 8 23V23.8889H9.77778V23C9.77778 21.8212 10.246 20.6908 11.0795 19.8573C11.913 19.0238 13.0435 18.5556 14.2222 18.5556H17.7778C18.9565 18.5556 20.087 19.0238 20.9204 19.8573C21.754 20.6908 22.2222 21.8212 22.2222 23V23.8889H24Z"
                              fill="#B6B6B6"
                            ></path>
                          </svg>
                          <p className=" text-[18px] font-medium">Anonymous</p>
                        </div>
                        <button
                          className="flex items-center gap-2 text-sm text-gray-400 hover:text-gray-500"
                          onClick={() => {
                            deleteMutation.mutate();
                          }}
                        >
                          <DeleteOutlined />
                          Delete
                        </button>
                      </div>
                      <div className="font-semibold text-xs w-[140px] py-0.5 rounded-md bg-[#EDEDED]">
                        neutral sentiment
                      </div>
                      <p className=" text-start">
                        The user expressed reluctance to provide their full name
                        and email address for privacy reasons. They engaged in a
                        conversation where the assistant repeatedly asked for
                        these details to proceed, but the user ultimately did
                        not provide them.
                      </p>

                      <div className="py-8 ">
                        <Table
                          columns={columns}
                          dataSource={dataSource}
                          pagination={false}
                          bordered
                          showHeader={false}
                          className="mx-auto "
                        />
                      </div>
                    </>
                  ) : null}
                  {answers?.data.length >= 1 ? (
                    answers?.data?.map((item, index) => (
                      <div
                        key={index}
                        className="w-full bg-white rounded-lg text-start"
                      >
                        {/* Question Text */}
                        <div className="mb-2">
                          <h3 className="text-sm font-semibold text-gray-400">
                            {item.question?.question_text}
                          </h3>
                        </div>

                        {/* Answers */}
                        <div className="space-y-1">
                          {item.question.question_type === "legal" ? (
                            // Special case for the "Certifying Statement" question
                            <div className="font-semibold rounded">
                              {item.answer[0]?.answer_text === "[1]"
                                ? "Agreed"
                                : "Disagreed"}
                            </div>
                          ) : (
                            // General case for other questions
                            item.answer.map((ans, idx) => (
                              <div key={idx} className="font-semibold rounded">
                                {ans?.answer_text}
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center w-full text-lg font-medium h-svh ">
                      No answers available
                    </div>
                  )}
                </div>
              )}
              {selectedTab.value === "share" &&
                (form?.url_id ? (
                  <div>
                    <iframe
                      src={`${publicUrl}`}
                      title="Embedded Form"
                      width="100%"
                      style={{ border: "none" }}
                      className="h-screen pb-4"
                    ></iframe>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-64 text-xl font-semibold">
                    <p>Form not published yet</p>
                  </div>
                ))}
              {selectedTab.value === "design" && (
                <div className="grid grid-cols-1 place-content-center">
                  <FormView editor={true} />
                </div>
              )}
            </div>
          </div>
        </CommonLayout>
      </QuestionProvider>

      {/* onSave modal */}
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        closeIcon={null}
        width={400}
        padding={0}
      >
        <div
          style={{
            maxHeight: "200px",
            padding: "0px",
          }}
        >
          <div class="p-5 pt-4 pb-2 text-center">
            <h2 class="tracking-tight font-medium text-xl flex items-center justify-center flex-col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                class="text-amber-500 h-6 w-6 mb-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              You have unsaved changes
            </h2>
            <p class="text-zinc-500 text-sm mt-0.5">
              Are you sure you want to continue?
            </p>
          </div>
          <div class="flex items-center justify-center gap-1 px-5 pt-4 pb-4">
            <button
              class="bg-black hover:bg-zinc-700 transition duration-150 rounded-lg py-2.5 px-4 text-white text-xs disabled:bg-zinc-700 font-medium flex items-center justify-center h-9 w-full"
              onClick={() => {
                navigate(ROUTES.DASHBOARD);
              }}
            >
              Continue without saving
            </button>
            <button
              class="whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50  hover:text-zinc-900  hover:bg-zinc-100 transition duration-150 rounded-lg py-2.5 px-4 text-xs disabled:bg-zinc-700 font-medium flex items-center justify-center h-9"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AIFormEditorLayout;

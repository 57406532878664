import {
  CheckOutlined,
  MailOutlined,
  EditOutlined,
  PhoneOutlined,
  FormOutlined,
  DownOutlined,
  CheckSquareOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
  GlobalOutlined,
  VideoCameraOutlined,
  AreaChartOutlined,
  CameraOutlined,
  FileOutlined,
  CloseOutlined,
  VideoCameraAddOutlined,
  CreditCardOutlined,
  DollarOutlined,
  CodeSandboxOutlined,
  StarOutlined,
  CalendarOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
  RedoOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";

export const QUESTION_COLORS = {
  multiple_choice: "#4CAF50", // Green
  short_text: "#2196F3", // Blue
  email: "#FFC107", // Amber
  dropdown: "#9C27B0", // Purple
  phone_number: "#FF5722", // Deep Orange
  section: "#3F51B5", // Indigo
  contact_info: "#00BCD4", // Cyan
  checkbox: "#8BC34A", // Light Green
  multiple_field: "#FF9800", // Orange
  legal: "#795548", // Brown
  country: "#607D8B", // Blue Grey
  ai_statement: "#673AB7", // Deep Purple
  statement: "#009688", // Teal
};

export const QUESTION_ICONS = {
  multiple_choice: <CheckOutlined />,
  short_text: <EditOutlined />,
  email: <MailOutlined />,
  dropdown: <DownOutlined />,
  phone_number: <PhoneOutlined />,
  section: <FormOutlined />,
  contact_info: <InfoCircleOutlined />,
  checkbox: <CheckSquareOutlined />,
  multiple_field: <FileTextOutlined />,
  legal: <FileTextOutlined />,
  country: <GlobalOutlined />,
  ai_statement: <FormOutlined />,
  statement: <FormOutlined />,
  video: <VideoCameraOutlined />,
  AI_graph: <AreaChartOutlined />,
  AI_image: <CameraOutlined />,
  file_upload: <FileOutlined />,
  yes_no: <CloseOutlined />,
  AI_video: <VideoCameraAddOutlined />,
  payment_method: <CreditCardOutlined />,
  subscription: <DollarOutlined />,
  language: <GlobalOutlined />,
  code_snippets: <CodeSandboxOutlined />,
  rating: <StarOutlined />,
  date: <CalendarOutlined />,
  facebook: <FacebookOutlined />,
  twitter: <TwitterOutlined />,
  instagram: <InstagramOutlined />,
  linkedin: <LinkedinOutlined />,
  youtube: <YoutubeOutlined />,
  redirect_to_url: <RedoOutlined />,
  long_text: <AlignLeftOutlined />,
};
export const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "multiple_choice",
  SHORT_TEXT: "short_text",
  EMAIL: "email",
  DROPDOWN: "dropdown",
  PHONE_NUMBER: "phone_number",
  SECTION: "section",
  CONTACT_INFORMATION: "contact_info",
  CHECKBOX: "checkbox",
  MULTIPLE_FIELD: "multiple_field",
  LEGAL: "legal",
  COUNTRY: "country",
  AI_STATEMENT: "ai_statement",
  STATEMENT: "statement",
  VIDEO: "video",
  AI_GRAPH: "AI_graph",
  AI_IMAGE: "AI_image",
  FILE_UPLOAD: "file_upload",
  YES_NO: "yes_no",
  AI_VIDEO: "AI_video",
  LANGUAGE_PREFERENCE: "language",
  SUBSCRIPTION_TYPE: "subscription",
  PAYMENT_TYPE: "payment_method",
  REDIRECT: "redirect_to_url",
  RATING: "rating",
  DATE: "date",
  CODES: "code_snippets",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
  YOUTUBE: "youtube",
  WEBSITE: "website",
  LONG_TEXT: "long_text",
};

export const FIELD_TYPE_GROUPS = {
  Recommended: [
    QUESTION_TYPES.SHORT_TEXT,
    QUESTION_TYPES.MULTIPLE_CHOICE,
    QUESTION_TYPES.DROPDOWN,
    QUESTION_TYPES.CONTACT_INFORMATION,
  ],
  "Contact Info": [
    QUESTION_TYPES.EMAIL,
    QUESTION_TYPES.PHONE_NUMBER,
    QUESTION_TYPES.CONTACT_INFORMATION,
    QUESTION_TYPES.COUNTRY,
  ],

  Choices: [
    QUESTION_TYPES.MULTIPLE_CHOICE,
    QUESTION_TYPES.DROPDOWN,
    QUESTION_TYPES.LEGAL,
    QUESTION_TYPES.YES_NO,
  ],
  "Text and Video": [
    QUESTION_TYPES.SHORT_TEXT,
    QUESTION_TYPES.LONG_TEXT,
    QUESTION_TYPES.VIDEO,
  ],
  "AI Features": [
    QUESTION_TYPES.AI_STATEMENT,
    QUESTION_TYPES.AI_GRAPH,
    QUESTION_TYPES.AI_IMAGE,
    QUESTION_TYPES.AI_VIDEO,
  ],

  "Other Fields": [
    QUESTION_TYPES.SECTION,
    QUESTION_TYPES.FILE_UPLOAD,
    QUESTION_TYPES.REDIRECT,
    QUESTION_TYPES.RATING,
    QUESTION_TYPES.DATE,
  ],
  /*  Preferences: [
    QUESTION_TYPES.LANGUAGE_PREFERENCE,
    QUESTION_TYPES.SUBSCRIPTION_TYPE,
    QUESTION_TYPES.PAYMENT_TYPE,
    QUESTION_TYPES.LANGUAGE_PREFERENCE,
    QUESTION_TYPES.SUBSCRIPTION_TYPE,
    QUESTION_TYPES.PAYMENT_TYPE,
  ], */
  Embeds: [
    QUESTION_TYPES.CODES,
    QUESTION_TYPES.FACEBOOK,
    QUESTION_TYPES.TWITTER,
    QUESTION_TYPES.INSTAGRAM,
    QUESTION_TYPES.LINKEDIN,
    QUESTION_TYPES.YOUTUBE,
  ],
};

export const FIELD_TYPE_OPTIONS = {
  [QUESTION_TYPES.MULTIPLE_CHOICE]: {
    type: QUESTION_TYPES.MULTIPLE_CHOICE,
    label: "Multiple Choice",
  },
  [QUESTION_TYPES.SHORT_TEXT]: {
    type: QUESTION_TYPES.SHORT_TEXT,
    label: "Short Text",
  },
  [QUESTION_TYPES.EMAIL]: { type: QUESTION_TYPES.EMAIL, label: "Email" },
  [QUESTION_TYPES.DROPDOWN]: {
    type: QUESTION_TYPES.DROPDOWN,
    label: "Dropdown",
  },
  [QUESTION_TYPES.PHONE_NUMBER]: {
    type: QUESTION_TYPES.PHONE_NUMBER,
    label: "Phone Number",
  },
  [QUESTION_TYPES.SECTION]: {
    type: QUESTION_TYPES.SECTION,
    label: "Section",
  },
  [QUESTION_TYPES.CONTACT_INFORMATION]: {
    type: QUESTION_TYPES.CONTACT_INFORMATION,
    label: "Contact Info",
  },
  [QUESTION_TYPES.LEGAL]: {
    type: QUESTION_TYPES.LEGAL,
    label: "Legal",
  },
  [QUESTION_TYPES.COUNTRY]: {
    type: QUESTION_TYPES.COUNTRY,
    label: "Country",
  },
  [QUESTION_TYPES.AI_STATEMENT]: {
    type: QUESTION_TYPES.AI_STATEMENT,
    label: "AI statement",
  },
  /*   [QUESTION_TYPES.STATEMENT]: {
    type: QUESTION_TYPES.STATEMENT,
    label: "Statement",
  }, */
};

export const FIELD_TYPE_SETTINGS = {
  [QUESTION_TYPES.SHORT_TEXT]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
    validations: {
      required: "Switch",
    },
    attachment: {
      link: "Image",
      placement: "Placement",
    },
  },
  [QUESTION_TYPES.MULTIPLE_CHOICE]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
      choices: "ArrayInput",
      allow_multiple_selection: "Switch",
    },
    validations: {
      required: "Switch",
    },
    attachment: {
      link: "Image",
      placement: "Placement",
    },
  },
  [QUESTION_TYPES.EMAIL]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.PHONE_NUMBER]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.DROPDOWN]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
      choices: "ArrayInput",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.SECTION]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
  },
  [QUESTION_TYPES.CONTACT_INFORMATION]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
      fields: "ArrayObject",
    },
  },
  [QUESTION_TYPES.CHECKBOX]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
      checkbox_label: "ShortText",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.COUNTRY]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.LEGAL]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
  },
  [QUESTION_TYPES.AI_STATEMENT]: {
    title: "AiStatement",
  },
  [QUESTION_TYPES.STATEMENT]: {
    title: "ShortText",
  },
};
export const SETTING_CONTEXT = {
  FIELD: "Field",
  GLOBAL: "Global",
};

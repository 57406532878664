import { useQuestionContext } from "../../Contexts/QuestionContext.jsx";
import React from "react";
import { TypeAnimation } from "react-type-animation";
import AnswerSection from "./AnswerSection.tsx";
import { Typography, Space, Flex } from "antd";
import { FadeAnimatedText } from "../Animated/FadeAnimatedText.jsx";
import {
  QUESTION_INDEX_ANIMATION_DURATION,
  TEXT_FADE_STAGGER_DURATION,
} from "../../Constants/animations.js";
import {
  getDynamicTextTitle,
  questionDescriptionStyle,
  questionTitleStyle,
  subQuestionTitleStyle,
} from "../../Constants/styles.js";
import { useFormContext } from "../../Contexts/FormContext.jsx";
import { motion } from "framer-motion";
import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";
import { TITLE_ANIMATION_VALUES } from "../../Constants/textAnimationValues.js";
import { QUESTION_TYPES } from "../../Constants/questionTypes.js";

export const Question = ({
  field,
  mainField = null,
  delay = 0,
  isLastQuestion = true,
  index = 0,
  editor,
}) => {
  const { styles, formTheme } = useFormContext();
  const { initial } = useQuestionContext();
  const { theme } = useFormEditorContext();
  const isEditor = location.pathname.includes("form-editor");
  const constTheme = isEditor ? theme : formTheme;

  // const q = currentQuestion;

  const isSubQuestion = !!mainField;
  const titleDelay = delay + QUESTION_INDEX_ANIMATION_DURATION;
  const descriptionDelay =
    titleDelay +
    TEXT_FADE_STAGGER_DURATION * (field?.title?.split(" ").length || 0);
  const answerDelay =
    descriptionDelay +
    TEXT_FADE_STAGGER_DURATION *
      (field?.properties?.description?.split(" ").length || 0);

  // console.log("field", field);

  if (true)
    return (
      <QuestionFormat
        title={
          <>
            {field?.type !== QUESTION_TYPES.AI_STATEMENT && (
              <Typography.Text
                style={
                  isSubQuestion
                    ? subQuestionTitleStyle(styles.secondary_color)
                    : questionTitleStyle()
                }
              >
                <div style={{ display: "flex", justifyContent: "left" }}>
                  {/* <FadeAnimatedText
                    disableAnimation={!initial}
                    textArray={
                      [
                        ...field?.title?.split(" "),
                        field?.validations?.required ? "*" : "",
                      ] || ["Loading..."]
                    }
                    delay={titleDelay}
                    editor={editor}
                  /> */}
                  <div
                    className="flex flex-col "
                    style={{
                      color: constTheme?.text_color,
                    }}
                  >
                    <span>
                      {field?.title} {field?.validations?.required && "*"}
                    </span>
                    <span
                      className="my-2 text-xs font-normal "
                      style={{
                        color: constTheme?.text_color,
                      }}
                    >
                      {field?.properties?.allow_multiple_selection &&
                        `choose as many as you like`}
                    </span>
                  </div>
                </div>
              </Typography.Text>
            )}
          </>
        }
        description={
          field?.properties?.description && (
            <Typography.Text style={questionDescriptionStyle}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  color: constTheme?.text_color,
                }}
              >
                {/* <FadeAnimatedText
                  disableAnimation={!initial}
                  delay={descriptionDelay}
                  textArray={field?.properties?.description.split(" ") || []}
                /> */}
                {field?.properties?.description}
              </div>
            </Typography.Text>
          )
        }
        answer={
          <AnswerSection
            field={field}
            delay={answerDelay}
            shouldShowNextButton={isLastQuestion}
            index={index}
            editor={editor}
          />
        }
        isSubQuestion={isSubQuestion}
      />
    );

  return (
    <div
    // style={{ backgroundColor: "red" }}
    >
      {/* {q.question} */}

      <Typography
        style={{
          // fontSize: "1.5em",
          alignItems: "left",
          // backgroundColor: 'red',
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={isSubQuestion ? 4 : 2}>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed once, initially
                field?.title || "Loading...",
                500,
              ]}
              style={{ textAlign: "left" }}
              speed={50}
              // style={{ fontSize: isSubQuestion ? "1.25em" : "1.5em" }}
              cursor={false}
              // repea
              // t={Infinity}
            />

            {field?.validations?.required && (
              <Typography style={{ color: "red", fontSize: "1em" }}>
                *
              </Typography>
            )}
          </div>
        </Typography.Title>
        <Space />
        <Typography.Title
          level={4}
          style={{ textAlign: "left", fontWeight: "normal", color: "grey" }}
        >
          <div style={{ display: "flex", justifyContent: "left" }}>
            {field?.properties?.description && (
              <TypeAnimation
                cursor={false}
                sequence={[
                  1000,
                  // Same substring at the start will only be typed once, initially
                  field?.properties?.description,
                  500,
                ]}
                speed={60}
                style={{ fontSize: "1em", textAlign: "left" }}
                // repea
                // t={Infinity}
              />
            )}
          </div>
        </Typography.Title>
      </Typography>
      <div style={{ height: "1vh" }} />
      {/* <Typography style={{ fontSize: "1.5em" }}>test</Typography> */}
      <AnswerSection field={field} />
      {/* <motion.div
        initial={{ translateY: 100 }}
        animate={{ translateY: 0 }}
        transition={{ delay: 500 }}
      > */}
      {/* </motion.div> */}
    </div>
  );
};

export const QuestionFormat = ({
  title,
  description,
  answer,
  attachment,
  placement,
  aiStatement = false,
  textArray,
  isLoading = false,
}) => {
  if (!aiStatement) {
    return (
      <div
        className={`grid items-center gap-2 ${
          attachment ? "grid-cols-2" : " grid-cols-1"
        } `}
      >
        <Flex
          vertical
          gap={10}
          className={`${
            attachment && placement === "left" ? "order-last" : ""
          }`}
        >
          <Typography
            style={{
              // fontSize: "1.5em",
              alignItems: "left",
              // backgroundColor: 'red',
              display: "flex",
              flexDirection: "column",
            }}
          >
            {title}
            {/* <Space /> */}
            {description}
          </Typography>
          {/* <div style={{ height: "1vh" }} /> */}
          {/* <Typography style={{ fontSize: "1.5em" }}>test</Typography> */}
          {answer}

          {/* <motion.div
        initial={{ translateY: 100 }}
        animate={{ translateY: 0 }}
        transition={{ delay: 500 }}
      > */}
          {/* </motion.div> */}
        </Flex>
        <img src={attachment} />
      </div>
    );
  } else {
    const { theme } = useFormEditorContext();
    if (isLoading) {
      return (
        <motion.div style={{ textAlign: "left" }} layout="position">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1, 0] }}
            transition={{ repeat: Infinity, duration: 1 }}
            style={{
              color: theme?.text_color,
              fontSize: getDynamicTextTitle(theme?.text_size),
              marginLeft: "5px",
            }}
          >
            |
          </motion.span>
        </motion.div>
      );
    }
    return (
      <motion.div style={{ textAlign: "left" }} layout="position">
        {textArray?.map((word, idx) => {
          return (
            <motion.span
              key={word + idx}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={TITLE_ANIMATION_VALUES}
              style={{
                color: theme?.text_color,
                fontSize: getDynamicTextTitle(theme?.text_size),
              }}
            >
              {word}
            </motion.span>
          );
        })}
      </motion.div>
    );
  }
};

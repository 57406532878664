import { Input } from "antd";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";
import {
  getDynamicTextTitle,
  getDynamicTextDescription,
} from "../../../Constants/styles";

export const EndScreenEditor = () => {
  const { TextArea } = Input;
  const { endScreen, setEndScreen, theme, setChanged } = useFormEditorContext();

  const updateEndScreen = (key, value) => {
    setEndScreen({
      ...endScreen,
      [key]: value,
    });
    setChanged(true);
  };

  const dynamicTextTitle = getDynamicTextTitle(theme);
  const dynamicTextDescription = getDynamicTextDescription(theme);
  return (
    <div
      className={`  py-3 
         flex flex-col items-center mx-auto justify-center h-full w-full`}
    >
      <TextArea
        value={endScreen.title}
        onChange={(e) => {
          updateEndScreen("title", e.target.value);
        }}
        //  placeholder="Controlled autosize"
        autoSize={{
          minRows: 1,
          maxRows: 5,
        }}
        style={{
          color: theme?.text_color,
          fontSize: dynamicTextTitle,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
          padding: 0,
        }}
        className=" w-[535px] bg-transparent  font-bold   focus:bg-transparent active:bg-transparent hover:bg-transparent"
      />

      <TextArea
        value={endScreen.description}
        onChange={(e) => {
          updateEndScreen("description", e.target.value);
        }}
        autoSize={{
          minRows: 1,
          maxRows: 5,
        }}
        style={{
          color: theme?.text_color,
          fontSize: dynamicTextDescription,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
          padding: 0,
        }}
        className=" my-1 w-[535px]  bg-transparent  focus:bg-transparent active:bg-transparent hover:bg-transparent"
      />
      {/* //<Socials /> */}
    </div>
  );
};

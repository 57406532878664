import React from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { QUESTION_TYPES } from "../../Constants/questionTypes";

export const SiderFieldCard = ({ field, index, label = null }) => {
  const { selectedField, setSelectedField, fields, setFields, saveChanges } =
    useFormEditorContext();

  const isSelected = selectedField === field;

  const removeField = (fieldId) => {
    const updatedFields = fields.filter((f) => f.id !== fieldId);
    setFields(updatedFields);
    setSelectedField(null);
  };

  return (
    <button
      className={`relative flex items-center w-full pl-1 transition duration-150  rounded-lg cursor-pointer hover:bg-zinc-100 ${
        isSelected ? "bg-zinc-200" : "bg-zinc-50"
      }`}
      key={index}
    >
      <div className="absolute w-2 h-8 transform -translate-y-1/2 bg-black rounded-r-full -left-6 top-1/2"></div>
      <button className="pl-1 pr-1.5 h-8 ">
        {field === "welcome-screen" || field === "end-screen" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-slot="icon"
            className="text-zinc-400 h-2.5 w-2.5"
          >
            <path
              fill-rule="evenodd"
              d="M2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm5-2.25A.75.75 0 0 1 7.75 7h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75v-4.5Z"
              clip-rule="evenodd"
            ></path>
          </svg>
        ) : (
          <svg
            class="text-zinc-400 h-2.5 w-2.5"
            fill="currentColor"
            viewBox="0 0 8 13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5 0C0.671631 0 0 0.671539 0 1.5C0 2.32846 0.671631 3 1.5 3C2.32837 3 3 2.32846 3 1.5C3 0.671539 2.32837 0 1.5 0Z"></path>
            <path d="M6.5 0C5.67163 0 5 0.671539 5 1.5C5 2.32846 5.67163 3 6.5 3C7.32837 3 8 2.32846 8 1.5C8 0.671539 7.32837 0 6.5 0Z"></path>
            <path d="M1.5 5C0.671631 5 0 5.67154 0 6.5C0 7.32846 0.671631 8 1.5 8C2.32837 8 3 7.32846 3 6.5C3 5.67154 2.32837 5 1.5 5Z"></path>
            <path d="M6.5 5C5.67163 5 5 5.67154 5 6.5C5 7.32846 5.67163 8 6.5 8C7.32837 8 8 7.32846 8 6.5C8 5.67154 7.32837 5 6.5 5Z"></path>
            <path d="M1.5 10C0.671631 10 0 10.6715 0 11.5C0 12.3285 0.671631 13 1.5 13C2.32837 13 3 12.3285 3 11.5C3 10.6715 2.32837 10 1.5 10Z"></path>
            <path d="M6.5 10C5.67163 10 5 10.6715 5 11.5C5 12.3285 5.67163 13 6.5 13C7.32837 13 8 12.3285 8 11.5C8 10.6715 7.32837 10 6.5 10Z"></path>
          </svg>
        )}
      </button>

      <button
        style={{ fontFamily: "Lexend Deca, san-serif" }}
        className="text-xs text-start px-1.5 font-normal w-full focus:outline-none py-3"
        onClick={() => {
          isSelected ? setSelectedField(null) : setSelectedField(field);
        }}
      >
        {field?.type === QUESTION_TYPES.AI_STATEMENT
          ? "AI Statement"
          : field?.title
          ? field?.title
          : label || field.label}
      </button>
      <button
        class={` ${
          field === "welcome-screen" || field === "end-screen"
            ? "hidden"
            : "inline-flex items-center  justify-center whitespace-nowrap rounded-lg text-sm font-medium ring-offset-white transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50   hover:bg-zinc-100 hover:text-black   h-8 w-8 group mr-1 ml-auto"
        }`}
        onClick={() => removeField(field.id)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
          class="h-3 w-3 text-zinc-400 group-hover:text-zinc-900"
        >
          <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z"></path>
        </svg>
      </button>
    </button>
  );
};
